.notification-dropdown .dropdown-menu.show {
  display: block;
  width: 350px;
  padding: 10px;
  border-radius: 10px;
  margin-left: -10px !important;
  margin-top: 5px;
  height: 540px !important;
  overflow-y: auto;
}

.header-dropdown .btn:focus {
  background-color: transparent;
  border-color: #DCE8EB !important;
  color: #dce8eb;
}

.header-dropdown .btn:hover{
  border-color: #DCE8EB !important;
  box-shadow: 0 0 0 0.05rem #DCE8EB !important;
  background: transparent;
}

.header-dropdown .btn:active {
  border-color: #dce8eb;
}

.header-dropdown .btn {
  background-color: #F6F7FA;
  border-color: #DCE8EB;
  color: #2E3B44;
  /* min-width: 170px; */
}

.notification-dropdown .btn-primary:focus,
.notification-dropdown .btn-primary:active {
  border-color: #dce8eb !important;
}

.notification-dropdown .btn-primary.dropdown-toggle {
  border-color: #DCE8EB;
}

.header-dropdown .btn-primary:focus,
.header-dropdown .btn-primary.focus {
  border-color: #DCE8EB !important;
  box-shadow: #DCE8EB !important;
}

.header-dropdown .btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem #DCE8EB !important;
}

.header-dropdown .notification-dropdown .btn{
  padding: 10px 10px 10px 10px !important;
}

.header-dropdown .notification-dropdown .notification__header .btn{
  height: 30px;
}

@media screen and (max-width: 767px){
  .header-dropdown .notification-dropdown .notification__header .btn{
    width: 130px;
  }
  }
  

.header-dropdown .show > .btn-primary.dropdown-toggle {
  border-color: #DCE8EB !important;
  box-shadow: 0 0 0 0.05rem #DCE8EB !important;
}
@media screen and (max-width: 830px){
  .header-dropdown .rdrDateRangePickerWrapper .rdrMonthsHorizontal{
    flex-direction: column;
  }
.header-dropdown .rdrDateRangePickerWrapper .rdrCalendarWrapper{
  height: 380px;
  overflow: auto;
}
}

@media screen and (max-width: 440px){
  .header-dropdown .rdrDateRangePickerWrapper .rdrDefinedRangesWrapper{
    display: none !important;
  }
}
@media screen and (min-width: 440px){
  .header-dropdown .rdrDateRangePickerWrapper .rdrDefinedRangesWrapper{
    width: 120px !important;
  }
}

@media screen and (min-width: 550px){
  .header-dropdown .rdrDateRangePickerWrapper .rdrDefinedRangesWrapper{
    width: 160px !important;
  }
}

@media screen and (max-width: 767px){
.notification__header .btn{
  width: 40px;
  font-size: 0.928rem;
}
}

.nk-sidebar-brand .active {
  border: none !important;
  color: none;
}

@media screen and (max-width: 1199px){
  .nk-header-wrap .warning-click-here{
    display: none;
  }
  .nk-brand-mobile .warning-click-here{
    margin-left: -50px;
  }
}

@media screen and (min-width:1272px) {
  .nk-header-wrap .alert p{
    margin-right: 40px !important;
    padding: 4px 0;
  }
}

@media screen and (min-width:1199px) {
.nk-header-wrap .alert{
  /* padding: 1rem 1rem 0rem; */
  margin-top: 20px;
}
.nk-brand-mobile .alert p{
  padding-right: 15px;
}
}

@media screen and (min-width:768px) and (max-width:1199px) {
  .nk-brand-mobile .alert{
    margin-top: -8px;
  }
}

@media screen and (max-width:767px) {
  .nk-brand-mobile .alert{
    padding: 0.2rem 1.25rem;
    display: none;
  }
  .nk-brand-mobile .alert p{
    padding-right: 15px;
  }
}

@media screen and (max-width:501px) {
  .nk-brand-mobile .alert{
    margin-left: -100px;
  }
}

@media screen and (max-width:358px) {
  .nk-brand-mobile .alert{
    margin-left: -140px;
  }
}

.pagination .active{
  border: none !important;
  color: none;
}



 /* .dropdown-hover-menu{display: none;}

 .nk-menu-item:hover
  .dropdown-hover-menu{ display: block; }
 .dropdown-hover-menu{ margin-top:0; } */


 /* .dropdown-hover-menu li a:active, .setting-item > a .active{
    border-right: 3px solid #4dc647 !important;
    color: #3eb53e !important;
}  */

/* .dropdown-hover-menu li a:active, .nk-menu-item a{
  border: none !important;
  color: black !important;
}  */

.upgrade-plan-h6 {
  color: #3EB53E;
  margin-bottom: 30px;
  border-bottom: 2px solid #3EB53E;
  padding-bottom: 10px;
}

