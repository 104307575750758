.was-validated .form-control:invalid, .form-control.is-invalid { 
    border-color: #e85347 !important; 
    padding-right: 2.125rem; 
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e85347' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e85347' stroke='none'/%3e%3c/svg%3e"); 
    background-repeat: no-repeat; 
    background-position: right !important; 
    background-size: 20px;
}

.input-error{
    font-size: 12px;
    color: rgb(116, 8, 8);
    padding-left: 20px;
    padding-top: 5px;
    font-weight: 500;
}


.form-control-wrap .is-hidden .psw-visibility{
    background: url('../images/auth/icon-eye.svg');
    width: 14px;
    height: 14px;
}

.form-control-wrap .is-shown .psw-visibility {
    background: url('../images/auth/icon-eye-off.svg');
    width: 14px;
    height: 14px;
}

.psw-invalid-feedback {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #e85347;
}