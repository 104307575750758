#footer {
    position: fixed;
    bottom: 0;
    height: 134px;
    width: 85%;   
    /* margin-left: 3.5%; */
    z-index: 999;

}

/* .nk-footer-fixed {
    margin-bottom: 2rem;
    margin-right: 1rem;
} */

.member-goal-container{
    border: 1px solid rgb(233, 231, 231);
    border-radius: 5px;
    padding: 1% 2%;
    display: flex;
    justify-content: center;
    overflow: unset;
    z-index: 999;
    width: 100%;
   align-items: center;
   background: #e6fbe6;
   margin:  0 auto !important;
}
.member-goal .progress_bar {
    display: flex;
    height: 1rem;
    overflow: hidden;
    line-height: 0px;
    font-size: 0.75rem;
    background-color: #fff;
    border-radius: 20px;
}
.member-goal-container .member-goal-container-first{
    margin-top: 8px;
}
.member-goal-container .member-goal-container-third{
    margin-top: 8px;
}

.counter-main-text{
    display: flex;
    justify-content: space-between !important;
}

.counter-main-text p{
margin-bottom: 0px;
}

.PhoneInputCountrySelectArrow {
    border: solid #3eb53e !important;
    border-width: 0 3px 3px 0 !important;
    display: inline-block !important;
    padding: 3px !important;
    transform: rotate(45deg) !important;
    -webkit-transform: rotate(45deg) !important;
    margin: 10px !important;
}

@media screen and (max-width:650px){
    #footer{
       position: fixed;
       bottom: 0%;
       width: 94%;
       right: 3%;
       z-index: 99 !important;
       margin: auto;
    }
}
