.dropdown-setting .sub-nav-item a:active,  .setting-item {
    border-right: 3px solid #4dc647 !important;
    color: #3eb53e !important;
  }

  .data-members .dropdown-menu,
  .nk-sidebar-company .dropdown-menu{
    scrollbar-color: #4dc647 #e0e0e0;
    scrollbar-width: thin;
  }

  .data-members .dropdown-menu::-webkit-scrollbar,
  .nk-sidebar-company .dropdown-menu::-webkit-scrollbar {
    max-width: 5px;
    max-height: 5px;
}

.data-members .dropdown-menu::-webkit-scrollbar-thumb,
.nk-sidebar-company .dropdown-menu::-webkit-scrollbar-thumb {
    background-color: #4dc647;
    box-shadow: inset 0 0 6px rgba(129, 121, 121, 0.3);
    border-radius: 10px;
    max-height: 5px;
}

.sidebar-tool__tip {
  position: relative;
  display: flex;
}

.sidebar-tool__tip  .tool__tip__text {
  visibility: hidden;
  min-width: -moz-fit-content;
  min-width: fit-content;
  background-color: #237d2d;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  left: -1%;
  
  top: -34px;
}

.sidebar-tool__tip:hover .tool__tip__text {
  visibility: visible;
}

.nk-sidebar-company .btn-company:hover{
  border-color: #c9d4d6 !important;
  box-shadow: 0 0 0.2rem #dce8eb !important;
}

.nk-sidebar-company .btn-company:focus{
  border-color: #c9d4d6 !important;
  box-shadow: 0 0 0.2rem #dce8eb !important;
  background: transparent;
}

.nk-sidebar-company .btn-company .form-check{
  padding-left: 0rem;
} 

@media screen and (max-width:1199px) {
  .nk-sidebar-company .btn-company span{
    margin-left: 20px;
  }
}


.form-control .PhoneInputCountrySelectArrow{
  opacity: 1;
  font-weight: 900;
  font-size: 1.5rem;
}

