@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

.text-bolder {
  font-weight: 800;
}

.text-bold,
.card-tag,
/* .btn__circle, */
#upgrade-plan .form-group .btn-radio label {
  font-weight: 700;
  /* padding: 20px 10px 20px 45px; */
  color: #56666d;
  width: 100%;
  margin-bottom: 0;
}

.text-semibold,
.nk-main
  .drawer--pay-invoice
  .bank-details
  .custom-radio
  .custom-control-label
  span:first-of-type,
.notification__block-head,
.notification__block-list-item .ht,
.is-light .active > .nk-menu-link,
.is-light .active > .nk-menu-link .nk-menu-text,
.form-group label,
.tool__tip__text,
.themeAlert .modal-content p span,
.daterangepicker,
.daterangepicker select.monthselect,
.daterangepicker select.yearselect,
.saved-card .title,
.themeTable__title,
.themeTable .data-name,
.themeTable .data-amount,
.themeTable .data-members,
.nk-sidebar-company .btn-company,
.clientTable.themeTable .themeTable__title-invoice,
.datatable-custom-reports,
.datatable-custom-reports .btn__circle,
.no-report-wrap h1 {
  font-weight: 600;
}

.text-md,
.nav-tabs .nav-link,
.btn,
.btn-sm,
.btn-sorting .btn__circle,
.btn-outline-light,
.form-link,
.input-group-text,
.datepicker td,
.tool__tip__content,
.ms-form__steps-link,
.ms-form__content--summary .invoice-summary .nk-ibx-list .nk-ibx-item-elem,
.ms-form__content--summary .invoice-summary .nk-ibx-item .sub-text,
.ms-form__content--summary .invoice-summary .nk-ibx-footer .tb-amount,
.colorpicker ul li,
.nk-menu-link,
.report-data span,
.setting-email__content__nav .nav-tabs .nav-item .nav-link {
  font-weight: 500;
}

.text-regular,
.custom-checkbox .custom-control-label,
.form-control,
.daterangepicker .ranges li,
.themeTable .overdue .data-invoice,
.themeTable .overdue .data-dueDate,
.clientTable.themeTable .data-name--email,
#upgrade-plan .form-group .btn-radio label span {
  font-weight: 400;
}

.text-lt {
  font-weight: 300;
}

.btn,
.tag,
.nk-sidebar {
  font-family: 'Inter', sans-serif;
}

.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td,
.daterangepicker select.monthselect,
.daterangepicker select.yearselect,
.notification__block-head,
.themeTable__title,
.clientTable.themeTable tr.clients--head th {
  text-transform: uppercase;
}

.tag,
.nk-header .nk-block-title,
.no-report-wrap h1 {
  text-transform: capitalize;
}

.ms-form__content--item .item__description {
  width: 100%;
}

.overflow,
.icon-circle,
.card-wrapper--outer,
[class^='user-avatar']:not([class*='-group']) {
  overflow: hidden;
}

.cards,
.card-list__item,
.card-list__item:after,
.block-relative,
.caret-up,
.caret-down,
.card-sm,
.card-sm__header,
.card-sm__body,
.input-group,
.form-group--file-upload .image-preview,
.themeAlert .modal-iconWrap,
.ms-form__steps-item,
.daterangepicker .calendar-table .prev,
.daterangepicker .calendar-table .next,
.daterangepicker td.available,
.daterangepicker th.available,
.daterangepicker td.in-range,
.daterangepicker td.active,
.daterangepicker td.active:hover,
select.customselect,
.colorpicker .hue,
.notification__block-list-item,
.dataTables_scroll,
.toolbar__wrap,
.nk-header-tools .nt-new,
.report-content__analytics .canvas-wrapper,
.auth-register .auth__banner,
body,
html {
  position: relative;
}

.absolute-center,
.block-absolute,
.overlay,
.block-absolute--right,
.card-list__item:after,
.card-wrapper--outer,
.card-wrapper--curve::before,
.drop-title,
.parallelogram,
.user-status.dropdown-indicator::after,
.themeAlert .modal-iconWrap .icon,
.ms-form__steps-item:after,
.daterangepicker td.available:before,
.daterangepicker th.available:before,
.daterangepicker td.end-date:after,
.daterangepicker td.today:before,
.daterangepicker td.active:before,
.daterangepicker td.active.today.in-range:before,
.daterangepicker td.active:hover:before,
.daterangepicker td.active.today.in-range:hover:before,
.colorpicker,
.notification__block-list-item.status-unread:after,
.notification__block-list-item.status-unread::before,
.notification__block-list-item p a::after,
.nk-header-tools .nt-new:before,
.report-content__analytics .canvas-wrapper:after,
.auth-register .auth__banner:before,
.auth-register .auth__banner .icon-banner-dots {
  position: absolute;
}

.overlay,
.block-absolute--right,
.card-list__item:after,
.card-wrapper--outer,
.card-wrapper--curve::before,
.card-sm.props::before,
.card-sm.props:after,
.form-group--file-upload .image-preview:after,
.nk-main .wrapper:before,
.themeAlert .modal-iconWrap .icon,
.ms-form__steps-item:after,
.daterangepicker .calendar-table .next span:after,
.daterangepicker .calendar-table .prev span:after,
.daterangepicker td.available:before,
.daterangepicker th.available:before,
.daterangepicker td.available.start-date + .in-range:after,
.daterangepicker th.available.start-date + .in-range:after,
.daterangepicker td.in-range:before,
.daterangepicker td.in-range.today:before,
.daterangepicker td.end-date:after,
.daterangepicker td.today:before,
.daterangepicker td.active:before,
.daterangepicker td.active.today.in-range:before,
.daterangepicker td.active:hover:before,
.daterangepicker td.active.today.in-range:hover:before,
.colorpicker .hue .drag:before,
.notification__block-list-item.status-unread:after,
.notification__block-list-item.status-unread::before,
.notification__block-list-item p a::after,
.nk-header-tools .nt-new:before,
.report-content__analytics .canvas-wrapper:after,
.auth-register .auth__banner:before {
  content: '';
}

.tag__wrapper .btn,
.clientTable.themeTable .data-invoice .btn {
  cursor: inherit;
}

.display-flex {
  display: flex;
}

.display-inline-flex {
  display: inline-flex;
}

.sticky {
  position: -moz-sticky !important;
  position: -ms-sticky !important;
  position: -o-sticky !important;
  position: sticky !important;
  top: 80px;
}

.transition-slow {
  transition: all 0.5s linear;
}

.rotate45 {
  transform: rotate(45deg);
}

.item-center,
.flex-item,
.btn,
.btn-icon,
.form-group--file-upload .image-preview figure,
.notification__img,
.saved-card .block-card__item .tag,
.nk-header-tools .nt {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.box-shadow,
.card--shadow {
  -ms-box-shadow: 0 0 20px rgba(0, 0, 0, 0.06) !important;
  -o-box-shadow: 0 0 20px rgba(0, 0, 0, 0.06) !important;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.06) !important;
}

.overlay,
.block-absolute--right,
.card-list__item:after,
.card-wrapper--outer,
.card-wrapper--curve::before {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.absolute-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bg-t50 {
  background: #f1f6fb;
}

.cards,
.card-list__item,
.card-list__item:after {
  min-width: 336px;
  min-height: 224px;
  font-size: 14px;
  background-color: #fff;
  color: #2c3039;
  padding: 38px;
  border-radius: 30px;
}

@media screen and (min-width: 1441px) {
  .cards,
  .card-list__item,
  .card-list__item:after {
    min-width: 370px;
    min-height: 240px;
    padding: 40px;
  }
}

.bg-dark .cards [class*='text-'],
.bg-dark .card-list__item [class*='text-'],
.bg-dark .card-list__item:after [class*='text-'],
.bg-dark .cards p,
.bg-dark .card-list__item p,
.bg-dark .card-list__item:after p {
  color: #2e3b44;
}

@keyframes popOut {
  0% {
    opacity: 0;
    transform: scale(0.9);
    top: 40px;
  }

  100% {
    opacity: 1;
    transform: scale(1);
    top: 56px;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    top: 130%;
  }

  100% {
    opacity: 1;
    top: 100%;
  }
}

@keyframes fadeDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes heightFade {
  0% {
    opacity: 0;
    height: 0;
  }

  100% {
    opacity: 1;
    height: 100%;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.text-primary {
  color: #3eb53e !important;
}

.text-default {
  color: #2e3b44;
}

.text-gray500 {
  color: #56666d;
}

.text-1 {
  font-size: 2.7143rem;
  line-height: 1;
  color: #2e3b44;
}

.text-2 {
  font-size: 1.8rem;
  line-height: 1.42;
  color: #2e3b44;
}

@media screen and (min-width: 1023px) {
  .text-2 {
    font-size: 2rem;
  }
}

.text-3 {
  font-size: 1.5714rem;
  line-height: 1;
  color: #2e3b44;
}

.text-4 {
  font-size: 1.4286rem;
  line-height: 1;
  color: #2e3b44;
}

.text-5,
#upgrade-plan .form-group .btn-radio label {
  font-size: 1.2857rem;
  line-height: 1.1;
  color: #2e3b44;
}

.text-6 {
  font-size: 1.1429rem;
  line-height: 1.2;
}

.text-7 {
  font-size: 1.0714rem;
  line-height: 1.3;
}

.text-8 {
  font-size: 1rem;
  line-height: 1.45;
}

.text-9,
.btn__circle,
.themeTable__title,
.clientTable.themeTable tr.clients--head th,
#upgrade-plan .form-group .btn-radio label span {
  font-size: 0.9286rem;
  line-height: 1.5;
}

@media screen and (max-width: 1199px) {
  .themeTable .themeTable__title{
    font-size: 0.7286rem;
  }
}

.text-normal {
  text-transform: none;
}

.text-primary {
  color: #3eb53e;
}

.text-error {
  color: #ff3a5e;
}

.text-info,
.notification__block-list-item p a {
  color: #155bdb !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a,
div,
li {
  font-family: 'Inter', sans-serif !important;
}

h5,
.h5,
h6,
.h6 {
  letter-spacing: 0;
}

*:focus {
  outline: 0 !important;
}

img {
  max-width: 100%;
  height: auto;
}

svg {
  height: auto;
}

[class*='img-rap'] {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.img-wrap {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
}

.img-wrap--repeat {
  background-repeat: repeat;
}

.img-wrap--contain {
  background-size: contain;
}

.img-auto img {
  height: 100% !important;
  width: auto !important;
}

.error-404 .img-auto img {
  max-height: 250px;
}

@media screen and (min-width: 1441px) {
  .error-404 .img-auto img {
    max-height: inherit;
  }
}

.error-server .img-auto img {
  max-height: 250px;
}

@media screen and (min-width: 1441px) {
  .error-server .img-auto img {
    max-height: inherit;
  }
}

p {
  margin-bottom: 32px;
  line-height: 1.5;
}

p small {
  font-size: 0.68em;
}

small {
  font-size: 0.5em;
  margin-bottom: 0;
}

@media screen and (min-width: 1023px) {
  small {
    font-size: 0.45em;
  }
}

blockquote {
  font-style: italic;
}

label {
  line-height: 20px;
  font-size: 14px;
}

input {
  font-size: 16px;
  max-height: 32px;
  border-radius: 5px;
  background-color: transparent;
  border: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}

input:focus {
  border: none;
  outline: none;
}

input.input-blank {
  background-color: transparent;
  border: none;
  padding: 2px;
}

input.input-blank__number {
  width: 50px;
  text-align: center;
}

section {
  padding: 50px 0;
}

@media screen and (min-width: 768px), print {
  section {
    padding: 70px 0;
  }
}

@media screen and (min-width: 1023px) {
  section {
    padding: 87px 0;
  }
}

iframe {
  width: 100%;
  max-width: 100%;
}

table tr,
table td,
table th {
  padding: 5px 10px;
}

table p {
  margin-bottom: 0;
}

table .td-min-w {
  min-width: 60px;
  display: inline-block;
}

.btn:focus,
button:focus {
  outline: none !important;
}

ul {
  padding-left: 0;
  margin-bottom: 0;
}

ul li {
  display: block;
  list-style: none;
}

figure {
  margin-bottom: 0;
}

a:hover {
  color: #2d9b36;
}

.p-2 {
  padding: 15px;
}

.p-3 {
  padding: 30px;
}

.py-100 {
  padding: 50px 0 !important;
}

@media screen and (min-width: 1023px) {
  .py-100 {
    padding: 70px 0 60px !important;
  }
}

@media screen and (min-width: 1441px) {
  .py-100 {
    padding: 100px 0 100px !important;
  }
}

.py-40 {
  padding: 25px 0 !important;
}

@media screen and (min-width: 1023px) {
  .py-40 {
    padding: 40px 0 !important;
  }
}

.py-50 {
  padding: 25px 0 !important;
}

@media screen and (min-width: 1023px) {
  .py-50 {
    padding: 50px 0 !important;
  }
}

.py-80 {
  padding: 40px 0 !important;
}

@media screen and (min-width: 1023px) {
  .py-80 {
    padding: 80px 0 !important;
  }
}

.pt-lg-0 {
  padding-top: 60px !important;
}

@media screen and (min-width: 1441px) {
  .pt-lg-0 {
    padding-top: 0 !important;
  }
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

@media screen and (min-width: 1023px) {
  .pr-6,
  .px-6 {
    padding-right: 56px !important;
  }
}

@media screen and (min-width: 1023px) {
  .pl-6,
  .px-6 {
    padding-left: 56px !important;
  }
}

.row.g-2 {
  margin-left: -25px;
  margin-right: -25px;
}

.row.g-2 > * {
  padding: 0 25px;
}

.ml-6 {
  margin-left: 67.2px !important;
}

.m-r-2 {
  margin-right: 10px;
}

.m-r-3 {
  margin-right: 20px;
}

.m-r-5 {
  margin-right: 40px;
}

.m-b-5 {
  margin-bottom: 40px;
}

@media screen and (min-width: 1023px) {
  .mt--60 {
    margin-top: -60px !important;
  }
}

h1 ~ table,
h2 ~ table,
p ~ table {
  margin-top: 20px;
}

.dot span:not(:last-child)::after {
  content: '.';
  margin: 0 5px;
  position: relative;
  top: -3px;
  font-weight: bold;
  opacity: 0.7;
}

.input-wrap--number .caret {
  border-radius: 10px;
}

input[type='checkbox'].toggle-switch {
  position: relative;
  display: block;
  appearance: none;
  top: 0;
  left: 0;
  width: 30px;
  height: 12px;
  margin: 5px;
  border-radius: 12px;
  background: #edf4f5;
  box-sizing: border-box;
  cursor: pointer;
}

input[type='checkbox'].toggle-switch:checked {
  background: rgba(179, 191, 197, 0.7);
}

input[type='checkbox'].toggle-switch:after {
  position: absolute;
  content: '';
  text-align: center;
  color: rgba(0, 0, 0, 0.1);
  top: -4px;
  left: -4px;
  width: 18px;
  height: 18px;
  border-radius: 18px;
  transition: 0.3s;
  background: #b3bfc5;
}

input[type='checkbox'].toggle-switch:checked:after {
  left: calc(100% + 3px);
  transform: translateX(-100%);
  background: #3eb53e;
}

input[type='checkbox'].toggle-switch:active:after {
  width: 27px;
}

.border__radius--50 {
  border-radius: 50px;
}

.border__radius--48 {
  border-radius: 48px;
}

.border__radius--15 {
  border-radius: 15px;
}

.border__radius--15 img {
  border-radius: 15px;
}

.border__radius--20 {
  border-radius: 20px;
}

.border__radius--20 img {
  border-radius: 20px;
}

.border__radius--10 {
  border-radius: 10px;
}

.border__radius--10 img {
  border-radius: 10px;
}

.border__radius--8 {
  border-radius: 8px;
}

.border__radius--4 {
  border-radius: 4px;
}

.border-soft {
  border-color: #cccfd8 !important;
}

.border-outline-primary {
  border-color: #3eb53e !important;
  border-width: 1px;
  border-style: solid;
}

.border-none {
  border: none;
}

.bg-white {
  background-color: #fff !important;
}

.bg-light {
  background-color: #f6fafa !important;
}

.bg-dark {
  background-color: #2e3b44 !important;
  color: #fff;
}

.bg-dark [class*='text-'],
.bg-dark p {
  color: #fff;
}

.bg-secondary {
  background-color: #fff !important;
}

.bg-secondary-gradient {
  background: #0c0e0d;
  background: linear-gradient(45deg, #0c0e0d, #334b6f);
}

.bg-primary {
  background-color: #3eb53e !important;
}

.bg-success {
  background-color: #4dc647 !important;
}

.bg-warning {
  background-color: #ffae00 !important;
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-light,
.bg-white {
  color: #000;
}

.column span,
.column small {
  display: block;
}

.props {
  position: relative;
}

.props::after,
.props::before {
  content: '';
  width: 100px;
  height: 100px;
  background-color: rgba(255, 255, 255, 0.08);
  position: absolute;
  top: -43px;
  right: 78px;
  border-radius: 50%;
}

.props::before {
  width: 30px;
  height: 30px;
  top: auto;
  bottom: 8px;
  left: 8%;
}

.props p {
  margin-bottom: 0;
}

.list > *:not(:last-child),
.list > li:not(:last-child) {
  margin-bottom: 10px;
}

.list-social li img {
  max-width: 80%;
}

@media screen and (min-width: 1199px) {
  .list-social li img {
    max-width: 100%;
  }
}

.list-social li:not(:last-child) {
  margin-right: 8px;
}

@media screen and (min-width: 1199px) {
  .list-social li:not(:last-child) {
    margin-right: 27px;
  }
}

.list-inline {
  margin-bottom: 5px;
  display: inline-block;
}

.list-inline li {
  display: inline-block;
}

.list-inline li:not(:last-child) {
  margin-right: 8px;
}

@media screen and (min-width: 768px), print {
  .list-inline--margin li:not(:last-child) {
    margin-right: 15px;
  }
}

.list-style {
  padding-left: 0px;
}

.list-style li {
  margin-bottom: 5px;
  position: relative;
  list-style: none;
}

.list-style--border {
  padding-left: 0;
}

.list-style--border li {
  padding: 12px 20px;
}

.list-style--border li:not(:last-child) {
  border-bottom: 1px solid #dce8eb;
}

.list-style--coln {
  padding-left: 0;
}

@media screen and (max-width: 991px) {
  .list-style--coln {
    margin-bottom: 35px;
  }
}

.list-style--coln li {
  position: relative;
  display: block;
  margin-bottom: 16px;
}

.list-style--coln-2 {
  padding-left: 1px;
}

.list-style--coln-2 li {
  display: inline-block;
  width: 49%;
}

@media screen and (min-width: 992px), print {
  .list-style--coln-2 li {
    width: 38%;
  }
}

@media screen and (min-width: 1199px) {
  .list-style--coln-2 li {
    width: 35%;
  }
}

@media screen and (min-width: 1441px) {
  .list-style--coln-2 li {
    width: 36%;
  }
}

.list-style--coln-2-last {
  padding-left: 1px;
}

.list-style--coln-2-last li {
  display: inline-block;
  width: 49%;
}

.list-style--coln-2-last li:last-child {
  width: 100%;
}

.list-style--circle li {
  display: inline-block;
  margin-bottom: 10px;
  position: relative;
}

@media screen and (min-width: 1023px) {
  .list-style--circle li {
    margin-bottom: 20px;
  }
}

.list-style--circle li:before {
  content: '';
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #3eb53e;
  top: 9px;
  left: -19px;
}

.list-style--icon li {
  display: flex;
}

.list-style--icon li [class*='icon-'] {
  min-width: 30px;
  text-align: center;
}

.list-style--icon li:not(:last-child) {
  margin-bottom: 10px;
}

.list-style-wrap {
  padding: 8px 0;
  margin: 0;
}

.list-style-wrap li {
  list-style: none;
  margin-bottom: 0;
}

.list-style-none {
  padding-left: 0;
}

.list-style-none li {
  list-style: none;
  margin-bottom: 10px;
}

.list-items {
  padding-left: 0;
  margin-bottom: 0;
}

.list-items ul {
  margin-bottom: 0;
}

.list-items li {
  display: inline-block;
}

.list-items--col4 li {
  display: inline-block;
  vertical-align: middle;
  width: 48%;
  margin-bottom: 22px;
}

@media screen and (min-width: 768px), print {
  .list-items--col4 li {
    width: 32%;
  }
}

@media screen and (min-width: 1023px) {
  .list-items--col4 li {
    width: 24.4%;
  }
}

.list-items .icon {
  width: auto;
}

.list-bg li {
  background-color: #f1f6fb;
  padding: 17.6px;
}

@media screen and (min-width: 1023px) {
  .list-bg li {
    padding: 24px;
  }
}

@media screen and (min-width: 1441px) {
  .list-bg li {
    padding: 31px;
  }
}

.link-item {
  color: #3eb53e;
  opacity: 1;
  display: block;
}

@media screen and (min-width: 1441px) {
  .link-item {
    font-size: 18px;
  }
}

.link-item:hover {
  color: #3eb53e !important;
  opacity: 0.9;
}

.link-item:hover .text-1,
.link-item:hover .text-2,
.link-item:hover .text-3 {
  color: #3eb53e;
}

.link-item:hover ~ figure {
  background-color: rgba(0, 0, 0, 0.5);
}

.link-item:hover ~ figure img {
  opacity: 0.7;
}

.link-item::after {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.link-item .icon-arrow-right::before {
  display: none;
}

@media screen and (max-width: 991px) {
  .link-item .icon-arrow-right svg {
    width: 9px;
  }
}

.link-text {
  color: #3eb53e;
}

.link-text .icon-arrow-right {
  left: 0;
  top: 1px;
  position: relative;
  transition: all 0.1s linear;
  margin-left: 10px !important;
}

@media screen and (min-width: 1023px) {
  .link-text .icon-arrow-right {
    top: 0;
  }
}

@media screen and (min-width: 1441px) {
  .link-text .icon-arrow-right {
    margin-left: 12px !important;
  }
}

.link-text:hover {
  color: #2d9b36;
}

.link-text:hover .icon-arrow-right {
  left: -3px;
}

.link-text:hover .icon-arrow-right svg path {
  fill: #b23507;
}

.link-active {
  color: inherit;
}

.link-active .fa,
.link-active .fas,
.link-active .far {
  color: #4dc647;
}

.link-disabled {
  color: rgba(220, 232, 235, 0.65);
}

.body-margin {
  padding-top: 95px;
}

@media screen and (min-width: 768px), print {
  .body-margin {
    padding-top: 100px;
  }
}

@media screen and (min-width: 992px), print {
  .body-margin {
    padding-top: 116px;
  }
}

@media screen and (min-width: 1441px) {
  .body-margin {
    padding-top: 118px;
  }
}

.block-absolute--center {
  height: auto;
  transform: translate(-50%);
  left: 50%;
}

.block-absolute--right {
  background-color: transparent;
  left: auto;
  right: 0;
  width: auto;
  padding: 0 18px;
}

.block-absolute--right-tp {
  left: auto;
  right: 0px;
  top: 0;
}

.block-absolute--bottom {
  top: auto;
  bottom: 15px;
}

.block-absolute--bottom-center {
  height: auto;
  transform: translate(-50%);
  left: 50%;
  top: auto;
  bottom: 30px;
  width: 100%;
}

@media screen and (min-width: 1441px) {
  .block-absolute--bottom-center {
    bottom: 45px;
  }
}

.block-absolute--bottom-right {
  top: auto;
  bottom: 0;
  right: 0;
  left: auto;
}

.block-absolute--topLeft {
  opacity: 0.1;
  left: -170px;
  top: -80px;
}

.block-absolute--topRight {
  left: auto;
  right: -40px;
  max-width: 600px;
  top: -131px;
  opacity: 0.2;
}

.block-center {
  text-align: center;
  margin: 0 auto 20px;
}

@media screen and (min-width: 1441px) {
  .block-center--max {
    max-width: 500px !important;
  }
}

a {
  color: #3eb53e;
}

a:hover {
  text-decoration: none;
}

.flex-item {
  height: 100%;
}

.play-btn {
  cursor: pointer;
}

.play-btn:hover {
  opacity: 0.9;
}

.btn {
  font-size: 1rem;
  line-height: 1;
  padding: 12px 31px;
  box-sizing: border-box;
  outline: 0;
  min-height: 40px;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;
  border-color: #3eb53e;
  border-radius: 5px;
}

@media screen and (max-width: 480px) {
  .btn {
    padding: 10px;
  }
}

.btn + .btn {
  margin-left: 16px;
}

@media screen and (max-width: 480px) {
  .btn + .btn {
    margin-left: 12px;
    width: 50%;
  }
}

.btn:focus {
  box-shadow: none;
}

.btn:disabled {
  background-color: #fff5e7;
  border: 1px solid #fff5e7;
  color: #afb7c1;
}

.btn-primary {
  background-color: #3eb53e;
  color: #fff;
}

.btn-primary:hover,
.btn-primary:focus {
  background-color: #2d9b36;
  border-color: #2d9b36;
  box-shadow: 0 0 0 0.09rem #51ce4b !important;
  outline: none;
}

.btn-primary:not(:disabled):not(.disabled):active {
  background-color: #2d9b36;
  border-color: #2d9b36;
}

.btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.btn-secondary {
  background-color: #fff;
  color: #56666d;
  border-color: #dce8eb;
}

.btn-secondary:hover,
.btn-secondary:focus {
  background-color: rgba(62, 181, 62, 0.1);
  border: 1px solid #3eb53e;
  color: #3eb53e;
}

.btn-secondary:not(:disabled):not(.disabled):active {
  background-color: #fff5e7;
  border: 1px solid #3eb53e;
}

.btn-tertiary {
  background-color: #fff;
  color: #3eb53e;
  border: none;
}

.btn-tertiary:hover,
.btn-tertiary:focus {
  background-color: #fff5e7;
}

.btn-tertiary:active {
  background-color: #fff5e7;
}

.btn-tertiary:disabled {
  background-color: #fff;
  border: 1px solid #fff5e7;
  color: #afb7c1;
}

.btn-danger {
  border-color: #ff3a5e;
  background-color: #ff3a5e;
}

.btn-danger [class*='icon-'] + span {
  margin-left: 8px;
}

.btn-lg {
  font-size: 1.1429rem;
  min-height: 48px;
  line-height: 16px;
  padding: 15px 37px;
}

.btn-sm {
  min-height: 40px;
  padding: 9px 27px;
  font-size: 1rem;
  line-height: 1;
  min-width: 170px;
}

.btn-xs {
  font-size: 1rem;
  padding: 8px 14px;
  max-height: 34px;
  min-width: 65px;
  min-height: auto;
  border-color: #dce8eb;
  background: #fff;
  border-radius: 8px;
  color: #56666d;
}

.data-actions .btn-xs:hover, .data-actions .btn-sm:hover, .data-actions .btn-lg:hover{
  border-color: #3eb53e !important;
  box-shadow: 0 0 0.2rem #dce8eb  !important;
  opacity: 0.9 !important;
}

.btn-xs img {
  margin-right: 10px;
}

.btn-xs .icon {
  font-size: 1rem;
}

@media screen and (max-width: 480px) {
  .btn-xs {
    font-size: 0.85rem;
  }
}

.btn-xs-icon {
  padding: 8px;
  min-height: 34px;
  min-width: 34px;
}

.btn-xs-icon img {
  margin-right: 0;
}

.btn-xs:hover {
  background: #fff;
  border-color: #dce8eb;
  opacity: 0.8;
  color: #56666d;
}

.btn-filter {
  max-width: 165px;
  min-width: 165px;
}

@media screen and (max-width: 480px) {
  .btn-filter {
    max-width: 400px;
    min-width: 290px;
  }
}

.btn-saved {
  color: #155bdb;
  background: #e7f3fe;
  border-color: #155bdb;
}

.btn-saved:hover {
  color: #155bdb;
  background: #e7f3fe;
  border-color: #155bdb;
}

.btn-notSaved {
  border: none;
  padding: 0;
  background: transparent;
}

.btn-notSaved:hover {
  background: transparent;
}

.btn-saved .icon,
.btn-notSaved .icon {
  font-size: 1.1429rem;
}

.btn-draft {
  color: #155bdb;
  border-color: #155bdb;
}

.btn-draft:hover,
.btn-draft:focus {
  color: #155bdb;
  border-color: #155bdb;
}

.btn-sorting {
  padding: 9px 14px;
  min-width: auto;
  border-color: #dce8eb;
}

@media screen and (max-width: 480px) {
  .btn-sorting {
    padding: 10px;
  }
}

.btn-sorting .icon {
  margin-right: 7px;
}

.btn-sorting .icon svg {
  margin-top: -2px;
  max-width: inherit;
}

.btn-sorting .icon svg path {
  stroke: #56666d;
}

.btn-sorting .btn__circle {
  color: #56666d;
  border-color: #dce8eb;
  font-size: 0.8571rem;
  margin: 0 0 0 5px;
  height: 17px;
  width: auto;
  line-height: 15px;
  padding: 0 5px !important;
  min-width: auto;
  display: flex;
  align-items: center;
}

.btn-sorting:hover,
.btn-sorting:focus,
.btn-sorting.active {
  color: #155bdb;
  border-color: #155bdb;
}

.btn-sorting:hover .icon svg path,
.btn-sorting:focus .icon svg path,
.btn-sorting.active .icon svg path {
  stroke: #155bdb;
}

.btn-sorting:hover .btn__circle,
.btn-sorting:focus .btn__circle,
.btn-sorting.active .btn__circle {
  color: #155bdb;
  border-color: #155bdb;
}

.btn-outline-default {
  border-color: #dce8eb;
  color: #2e3b44;
  background: #fff;
}

.btn-outline-default-icon {
  padding: 6px 20px;
}

.btn-outline-default:hover,
.btn-outline-default:focus {
  /* border-color: #3eb53e; */
  /* box-shadow: 0 0 0.1rem #32b92b  !important; */
  background-color: #f6f7fa;
  color: #2e3b44;
}

.btn-outline-primary {
  outline: none;
  border-color: #3eb53e;
  color: #3eb53e;
  min-width: 165px;
}

.btn-outline-primary:hover {
  background-color: #3eb53e;
  border-color: #3eb53e;
}

.btn-outline-tertiary {
  border-color: #3eb53e;
  color: #3eb53e;
  min-width: 165px;
}

.btn-outline-tertiary:hover {
  background-color: #3eb53e;
  border-color: #3eb53e;
}

.btn-outline-light {
  border-radius: 5px;
  border: 1px solid #dce8eb;
  color: #56666d;
  min-height: 34px;
  padding: 10px;
  font-size: 1rem;
  line-height: 1;
  background-color: #ffffff;
}

.btn-outline-light:hover {
  background-color: #3eb53e;
  border-color: #3eb53e;
  color: #ffffff;
}

.btn-dashed-primary {
  background-color: #f2fded;
  color: #2d9b36;
  border: 1px dashed #3eb53e;
}

.btn-default {
  background-color: #f6f7fa;
  border-color: #dce8eb;
  color: #2e3b44;
  min-width: 170px;
}

.btn-default:hover,
.btn-default:focus {
  color: #2e3b44;
  background: #fff;
}

.btn-plaintext {
  color: #155bdb;
  font-weight: 400;
  min-height: auto;
  outline: none;
  border: none;
  padding: 0;
  line-height: 1;
  background: transparent;
}

.btn-plaintext:hover {
  color: #155bdb;
}

.btn__circle {
  min-width: 30px;
  line-height: 18px;
  height: 20px;
  border: 1px solid;
  color: #fff;
  border-radius: 20px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;
}

.btn--all {
  color: #56666d;
  border-color: #dce8eb;
}

.btn--all .btn__circle {
  background: #edf4f5;
  border-color: #dce8eb;
  color: #56666d;
}

.btn--all:hover {
  color: #56666d;
  border-color: #dce8eb;
}

.btn--paid {
  color: #2d9b36;
  border-color: #3eb53e;
}

.btn--paid .btn__circle {
  background: #3eb53e;
  border-color: #3eb53e;
}

.btn--paid:hover {
  color: #2d9b36;
  border-color: #3eb53e;
}

.btn--outstanding {
  color: #db8e00;
  border-color: #ffae00;
}

.btn--outstanding .btn__circle {
  background: #ffae00;
  border-color: #ffae00;
}

.btn--outstanding:hover {
  color: #db8e00;
  border-color: #ffae00;
}

.btn--overdue {
  color: #db2a5b;
  border-color: #ff3a5e;
}

.btn--overdue .btn__circle {
  background: #ff3a5e;
  border-color: #ff3a5e;
}

.btn--overdue:hover {
  color: #db2a5b;
  border-color: #ff3a5e;
}

.btn-icon {
  min-width: 40px;
  min-height: 40px;
  padding: 0;
  border: 1px solid #dce8eb;
}

.btn-icon .icon {
  width: auto;
}

.btn-icon svg {
  width: 18px;
}

.btn-icon:disabled {
  color: #fed49e;
}

.actions {
  display: flex;
}

@media screen and (max-width: 480px) {
  .actions .btn {
    width: 50%;
  }
}

.actions .btn + .btn {
  margin-left: 16px;
}

.custom-checkbox {
  min-height: 16px;
  padding-left: 32px;
}

.custom-checkbox .custom-control-input {
  height: 16px;
  cursor: pointer;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #3eb53e;
  border-color: #3eb53e;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background: url('../images/auth/check.svg') no-repeat center;
}

.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

.custom-checkbox .custom-control-label {
  color: #2e3b44 !important;
  cursor: pointer;
  /* font-size: 1.1429rem; */
  line-height: 1.45 !important;
  height: 23.2px !important;
  margin-bottom: 0 !important;
}

@media screen and (min-width:770px){
.custom-checkbox .custom-control-label {
  font-size: 1rem;
  }
}

.custom-checkbox .custom-control-label:after,
.custom-checkbox .custom-control-label:before {
  top: 0;
  left: -32px;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  border-color: #f1f6fb;
}

.custom-checkbox .custom-control-label:before {
  border: 0.5px solid #b3bfc5;
}

.custom-control-label {
  padding-top: 0;
}

.icon-primary .fa,
.icon-primary .far,
.icon-primary .fas,
.icon-primary .fab {
  color: #3eb53e;
}

.icon-primary svg,
.icon-primary path {
  fill: #3eb53e;
}

.icon-primary svg g,
.icon-primary path g {
  stroke: #3eb53e;
}

.icon-primary svg g path,
.icon-primary svg g line,
.icon-primary path g path,
.icon-primary path g line {
  stroke: #3eb53e;
}

.icon-grey .fa,
.icon-grey .far,
.icon-grey .fas,
.icon-grey .fab {
  color: #b3bfc5;
}

.icon-grey svg,
.icon-grey path {
  fill: #b3bfc5;
}

.icon-grey svg g,
.icon-grey path g {
  stroke: #b3bfc5;
}

.icon-grey svg g path,
.icon-grey svg g line,
.icon-grey path g path,
.icon-grey path g line {
  stroke: #b3bfc5;
}

.icon-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 100px;
  background-color: #f1f6fb;
  margin-bottom: 0;
  position: relative;
  z-index: 1;
}

.icon-sm {
  width: 16px;
}

.icon-sm svg,
.icon-sm img {
  width: 15px;
}

.icon-status-info:after {
  background-color: #3eb53e;
}

.icon-status::after {
  top: auto;
  bottom: -1px;
  right: -1px;
  width: 11px;
  height: 11px;
  border-width: 3px;
}

[class*='icon-'] + span {
  margin-left: 10px;
}

.card-list__item {
  margin-bottom: 35px;
  border-radius: 30px 30px 30px 0;
}

@media screen and (min-width: 1441px) {
  .card-list__item {
    min-width: 370px;
    min-height: 240px;
    padding: 37px;
  }
}

.card-list__item:after {
  border-radius: 30px 30px 30px 0;
  z-index: -1;
  top: 15px;
  left: 15px;
  background-color: #dddfe3;
}

.card-list__item h5,
.card-list__item .text-7 {
  font-weight: 600;
}

.card-list__item p {
  margin-bottom: 22px;
  font-size: 14px;
  line-height: 20px;
}

@media screen and (min-width: 1441px) {
  .card-list__item p {
    margin-bottom: 37px;
  }
}

.bg-dark .card-list__item [class*='text-'],
.bg-dark .card-list__item p {
  color: #2e3b44;
}

.card__modal--max {
  max-height: 450px;
  min-height: 450px;
}

.card-sm {
  max-width: 350px;
  min-height: 370px;
  border-radius: 20px;
}

.card-sm.props:before {
  top: -83px;
  z-index: -1;
}

.card-sm.props:after {
  right: -160px;
  bottom: -50px;
  z-index: -2;
}

.card-stats {
  padding: 20px 15px 25px 15px;
  background: #ffffff;
  border: 1px solid #dce8eb;
  border-radius: 7px 7px 10px 10px;
}

@media screen and (min-width: 1441px) {
  .card-stats {
    padding: 20px 22px 25px 22px;
  }
}

.card-stats__title {
  margin-bottom: 10px;
  line-height: 15px;
  color: #56666d;
  font-size: 15px;
  text-align: left;
}

.card-stats h5 {
  margin-bottom: 0;
  line-height: 22px;
}

.card-stats__amount {
  line-height: 15px;
  color: #2e3b44;
}

.card-stats__amount span {
  margin-right: 6px;
}

.card-stats.top-border-warning {
  border-top-color: #ffae00;
}

.card-stats.top-border-success {
  border-top-color: #4dc647;
}

.card-stats.top-border-info {
  border-top-color: #4b88e9;
}

.card-stats.top-border-error {
  border-top-color: #ff3a5e;
}

.card-stats.top-border-gray {
  border-top-color: #b3bfc5;
}

.card-stats[class*='top-border-'] {
  border-top-width: 6px;
}

.card-wrapper {
  padding: 55px;
}

.card-wrapper--outer {
  background-color: transparent;
  border-radius: 20px;
}

.card-wrapper--outer .list-style--icon {
  position: relative;
  left: -5px;
}

.card-wrapper--curve {
  padding: 48px 40px;
  background-color: #fff;
  border-radius: 0 150px 0 0;
}

@media screen and (min-width: 992px), print {
  .card-wrapper--curve {
    border-radius: 0 200px 0 0;
    padding: 44.8px 24px;
  }
}

@media screen and (min-width: 1199px) {
  .card-wrapper--curve {
    padding: 48px 44.8px 48px 48px;
  }
}

@media screen and (min-width: 1441px) {
  .card-wrapper--curve {
    padding: 55px 48px 55px 55px;
  }
}

.card-wrapper--curve::before {
  display: none;
}

@media screen and (min-width: 992px), print {
  .card-wrapper--curve::before {
    display: block;
    height: 60%;
    background: #fff;
    transform: scale(1.6) rotate(60deg);
    top: 80px;
    border-radius: 400px 400px 0 0;
    left: auto;
    right: -8px;
  }
}

@media screen and (min-width: 1199px) {
  .card-wrapper--curve::before {
    height: 62%;
    top: 90px;
  }
}

.card-tag {
  height: auto;
  top: 20px;
  right: -10px;
  color: #fff;
}

@media screen and (min-width: 992px), print {
  .card-tag {
    top: 24px;
    right: -8px;
  }
}

.card-bordered {
  border: 1px solid #dce8eb;
  border-radius: 10px;
}

.caret {
  display: flex;
  flex-direction: column;
  background-color: #f1f6fb !important;
}

.caret-up {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-bottom: 6px solid #445668;
  border-top: 0;
  top: 5px;
  cursor: pointer;
}

.caret-up:hover {
  border-bottom-color: #0f172e;
}

.caret-down {
  cursor: pointer;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-top: 6px solid #445668;
  border-bottom: 0;
  top: -2px;
}

.caret-down:hover {
  border-top-color: #0f172e;
}

.tag {
  height: 32px;
  line-height: 20px;
  padding: 5px 9px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

@media screen and (max-width: 1199px) {
  .tag {
    font-size: 0.85rem;
  }
}

.tag--outstanding {
  color: #db8e00;
  background-color: #fff7e6;
  border-color: #fff7e6;
}

.tag--draft{
  color: #db8e00;
  background-color: #fff7e6;
  border-color: #fff7e6;
}

.tag--paid {
  color: #33aa38;
  background-color: #e8fcdb;
  border-color: #e8fcdb;
}

.tag--void {
  color: #56666d;
  background-color: #f6fafa;
  border-color: #f6fafa;
}

.tag--overdue {
  color: #db2a5b;
  background-color: #ffefeb;
  border-color: #ffefeb;
}

.tag__icon {
  border: none;
  display: none;
  padding: 5px 0;
}

.tag__icon--img {
  padding: 0;
  border: 1px solid #dce8eb;
  min-width: 48px;
  background: #fff;
}

.tag__wrapper ul li {
  display: inline-block;
}

.tag--seen .tag__icon {
  display: inline-block;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  background-color: transparent !important;
  background-clip: border-box;
  -webkit-background-clip: text;
}

textarea.form-control {
  resize: none !important;
  line-height: 22px;
  min-height: 100px;
}

textarea.form-control:focus{
  border-color: #726f6f !important;
  opacity: 0.7;
}
textarea.form-control:focus::placeholder{
  opacity: 0.5;
}


textarea.form-control-md {
  height: 108px;
}

textarea.form-control-lg {
  height: 142px;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
}

.form-row .col {
  padding: 0 5px;
}

.form-control {
  color: #56666d;
  min-height: 55px;
  font-size: 1rem;
  line-height: 1;
  background-color: #f5f8f9;
  border: none;
  outline: 0;
  border-radius: 5px;
  padding: 14.9996px 10.0002px 1.0714rem 1.1429rem;
  box-shadow: none;
}

.form-control:focus {
  border-color: #6e6f70 !important;
  /* opacity: 0.7; */
}


/* .form-control:focus,
.form-control:not(:placeholder-shown) {
  background-color: transparent !important;
  box-shadow: none;
  border: 1px solid #9ea1a1 !important;
  /* opacity: 0.7; */
/*} */

.form-control::placeholder {
  color: #56666d;
}

.form-control ~ .invalid {
  color: #ff3a5e;
}

@media screen and (max-width: 480px) {
  .form-control {
    min-height: 50px;
  }
}

.form-group {
  margin-bottom: 1rem;
}

@media screen and (min-width: 481px), print {
  .form-group {
    margin-bottom: 1.2rem;
  }
}

@media screen and (min-width: 768px), print {
  .form-group {
    margin-bottom: 1.7143rem;
  }
}

.form-group label {
  color: #56666d;
  font-size: 1rem;
  line-height: 1;
  margin-bottom: 6px;
}

.form-group--multiple-input .form-control-wrap:first-of-type {
  width: 181px;
  margin-right: 0.7143rem;
  margin-bottom: 0.7143rem;
}

@media screen and (max-width: 480px) {
  .form-group--multiple-input .form-control-wrap:first-of-type {
    width: 100%;
    margin-right: 0;
  }
}

.form-group--multiple-input .form-control-wrap:nth-of-type(2) {
  width: 109px;
}

@media screen and (max-width: 480px) {
  .form-group--multiple-input .form-control-wrap:nth-of-type(2) {
    width: 100%;
  }
}

.form-group--multiple-input .form-control-wrap .form-control {
  width: 100%;
}

.form-group--multiple-input .form-control-wrap .form-control:focus{
  border-color: #636060 !important;
  opacity: 0.7;
}
.form-group--multiple-input .form-control-wrap .form-control:focus::placeholder{
  opacity: 0.5;
} 

.form-group .iti {
  width: 100%;
}

.form-group .iti__selected-flag {
  height: 40px;
}

@media screen and (min-width: 481px), print {
  .form-group .iti__selected-flag {
    height: 44px;
  }
}

.form-group .iti__country-list {
  border-radius: 5px;
  width: 345px;
  top: 49px;
}

.form-group .iti__country {
  font-size: 0.7857rem;
}

.form-link {
  color: #155bdb;
  font-size: 1.1429rem;
  line-height: 1.45;
}

@media screen and (min-width:770px) {
  .form-link{
    font-size: 1rem !important;
  }
}

.form-note {
  font-style: normal;
  color: #56666d;
  font-size: 0.9286rem;
  line-height: 1;
  margin-top: 8px;
}

.form-inline {
  align-items: start;
}

.input-group {
  height: 100%;
}

.input-group-prepend {
  color: #56666d;
  background: #edf4f5;
}

.input-group-text {
  border-color: #dce8eb;
  padding: 14px;
  font-size: 1rem;
  line-height: 14px;
  border-radius: 5px 0 0 5px;
}

@media screen and (max-width: 480px) {
  .input-group-text {
    padding: 12px;
  }
}

/* .select2-container--default .select2-selection--single {
  border-color: #dce8eb !important;
  height: 3.1429rem !important;
  font-size: 1rem !important;
  border-radius: 5px;
}

.select2-container--default .select2-selection--single:focus {
  box-shadow: none;
  border-color: #dce8eb;
  outline: 0;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 100% !important;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow::after {
  content: '' !important;
  border-top: 4px solid #2e3b44;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  transition: transform 0.5s;
}

.select2-container--default .select2-selection__rendered {
  padding: 1.0714rem 2.2857rem 15px 16px !important;
  line-height: 1 !important;
  color: #56666d !important;
}

.select2-container--default.select2-container--open .select2-selection--single {
  border-color: #dce8eb !important;
  border-radius: 5px 5px 0 0 !important;
}

.select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow::after {
  transform: rotate(-180deg);
}

.select2-container--open .select2-dropdown {
  border-color: #dce8eb;
  color: #56666d;
}

.select2-dropdown {
  border-radius: 0 0 5px 5px;
}

.select2-results__option--highlighted[aria-selected] {
  background: #f6fafa !important;
} */

.datepicker {
  border-radius: 5px;
}

.datepicker td {
  border-radius: 50%;
  width: 34px !important;
  height: 34px;
}

.datepicker table tr th {
  color: #56666d;
}

.datepicker table tr td.today {
  background-color: #f2fded;
  color: #3eb53e;
}

.datepicker table tr td.today:hover {
  background-color: #3eb53e !important;
}

.datepicker table tr td.active.active {
  background-color: #3eb53e;
}

.datepicker table tr td.active.active:hover {
  background-color: #3eb53e;
}

.datepicker table tr td span.focused {
  background-color: #3eb53e;
}

.datepicker table tr td span:hover {
  background-color: #f2fded;
  color: #2d9b36;
}

.input-custom .form-control-file {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 122px;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.drop-file {
  margin-bottom: 10px;
  border: 1px dashed #9bb1ca;
  font-size: 1rem;
  line-height: 18px;
  font-weight: 400;
  color: #dce8eb;
  border-radius: 6px;
  background-color: #f6fafa;
}

.drop-file:before {
  content: attr(data-title);
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 42px;
  visibility: visible;
  text-align: center;
  background-image: url(../images/upload.png);
  background-repeat: no-repeat;
  background-position: 50% 15%;
}

@media screen and (max-width: 480px) {
  .drop-file:before {
    padding-top: 20px;
  }
}

.drop-title {
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  top: 46%;
  width: 100%;
  color: #56666d;
  line-height: 1.5;
}

@media screen and (max-width: 480px) {
  .drop-title {
    top: 36%;
  }
}

.custom-checkbox {
  min-height: 16px;
  padding-left: 32px;
}

.custom-checkbox .custom-control-input {
  height: 16px;
  cursor: pointer;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #3eb53e;
  border-color: #3eb53e;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background: url('../images/auth/check.svg') no-repeat center;
}

.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

.custom-checkbox .custom-control-label {
  color: #2e3b44 !important;
  cursor: pointer;
  /* font-size: 1.1429rem; */
  line-height: 1.45 !important;
  height: 23.2px !important;
  margin-bottom: 0 !important;
}

.custom-checkbox .custom-control-label:after,
.custom-checkbox .custom-control-label:before {
  top: 0;
  left: -32px;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  border-color: #f1f6fb;
}

.custom-checkbox .custom-control-label:before {
  border: 0.5px solid #b3bfc5;
}

.custom-control-label {
  padding-top: 0;
}

.parallelogram {
  height: 182px;
  width: 166px;
  transform: skew(150deg);
}

.parallelogram-primary {
  background-color: #3eb53e;
}

.parallelogram-warning {
  background-color: #ffae00;
}

.custom-switch {
  min-height: 18px;
}

.custom-switch .custom-control-label {
  margin-bottom: 0;
}

.custom-switch .custom-control-label span {
  display: block;
}

.custom-switch .custom-control-label span:first-of-type {
  margin-bottom: 0.7143rem;
  line-height: 1;
  color: #2e3b44;
}

.custom-switch .custom-control-label span:nth-of-type(2) {
  color: #56666d;
  line-height: 1.4;
}

.custom-switch .custom-control-label:before {
  height: 24px;
  width: 42px;
  background-color: #b3bfc5;
  border-radius: 57px;
  border: none;
  top: 0;
}

.custom-switch .custom-control-label:after {
  height: 18px;
  width: 18px;
  background-color: #fff;
  top: 3px;
  bottom: 3px;
  border-radius: 50%;
  -ms-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  -o-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(18px);
  background-color: #f1f6fb;
}

.user-avatar img,
[class^='user-avatar']:not([class*='-group']) img {
  border-radius: 0;
}

.user-name {
  font-size: 15px;
  color: #2e3b44;
}

.user-status {
  padding-bottom: 0;
  color: #56666d;
  font-size: 13px;
}

.user-info.dropdown-indicator::after {
  top: -50%;
  /* right: 22px; */
  position: relative;
  margin-right: -175px;
  /* right: 0; */
  transition: all 0.2s cubic-bezier(0.25, 0.25, 0.75, 0.75);
}

@media screen and (max-width:1199px) {
  .user-info.dropdown-indicator::after{
    margin-right: -230px;
  }
  .user-info.dropdown-indicator .full-name{
    display: block !important;
  }
  .user-info.dropdown-indicator .split-name{
    display: none !important;
  }
}

@media screen and (min-width:1199px) {
.user-info.dropdown-indicator .split-name{
  display: block;
}
.user-info.dropdown-indicator .full-name{
  display: none;
}
}

.dropdown .user-info.dropdown-indicator::after {
  transform: rotate(-180deg);
}

.dropdown.show .user-info.dropdown-indicator::after {
  transform: rotate(0);
}

[class^='user-avatar']:not([class*='-group']) {
  width: 32px;
  height: 32px;
  border-radius: 8px;
}

.file-upload-input {
  display: none;
}

.file-upload-label span:hover{
  color: #4dc647 !important;
}

.form-group--file-upload .image-preview {
  height: 48px;
  width: 48px;
  cursor: pointer;
}

.form-group--file-upload .image-preview figure,
.form-group--file-upload .image-preview img {
  height: auto;
  width: 100%;
}

.form-group--file-upload .image-preview figure {
  margin-bottom: 0;
  overflow: hidden;
  border: 1px solid #dce8eb;
  background: #f6fafa;
  border-radius: 12px;
}

.form-group--file-upload .image-preview img {
  object-fit: cover;
}

.form-group--file-upload .image-preview:after {
  background: url('../images/invoice-settings/icon-add.svg') center no-repeat;
  background-size: contain;
  height: 16px;
  width: 16px;
  padding: 0;
  top: auto;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 7;
}

.tool__tip {
  position: relative;
}

.t-w{
  position: fixed !important;
}

.tool__tip:hover .tool__tip__content {
  opacity: 1;
  top: 107%;
  visibility: visible;
}

.tool__tip__text {
  margin-top: 5px;
  font-size: 0.9375rem;
  line-height: 1rem;
  width: 100px;
}

.tool__tip__content {
  position: absolute;
  opacity: 0;
  background: #2e3b44;
  color: #fff;
  padding: 10px;
  border-radius: 10px;
  z-index: 9;
  left: 73%;
  top: 95%;
  text-align: left;
  text-transform: capitalize;
  transition: 0.5s ease;
  visibility: hidden;
  font-size: 0.75rem;
  line-height: 0.75rem;
}

.pagination {
  justify-content: flex-end !important;
}

.dataTables_wrapper .themeTable__pagination {
  padding: 30px 0;
}

.dataTables_wrapper .themeTable__pagination .dataTables_paginate ul.pagination {
  margin-right: -7px;
  margin-left: -7px;
}

@media screen and (min-width: 768px), print {
  .dataTables_wrapper
    .themeTable__pagination
    .dataTables_paginate
    ul.pagination {
    justify-content: flex-end;
  }
}

.dataTables_wrapper
  .themeTable__pagination
  .dataTables_paginate
  ul.pagination
  .page-item {
  padding: 0 7px;
}

.dataTables_wrapper
  .themeTable__pagination
  .dataTables_paginate
  ul.pagination
  .page-item:first-child
  .page-link,
.dataTables_wrapper
  .themeTable__pagination
  .dataTables_paginate
  ul.pagination
  .page-item:last-child
  .page-link {
  border-radius: 8px;
  font-size: 0;
}

.dataTables_wrapper
  .themeTable__pagination
  .dataTables_paginate
  ul.pagination
  .page-item:first-child
  .page-link:before,
.dataTables_wrapper
  .themeTable__pagination
  .dataTables_paginate
  ul.pagination
  .page-item:first-child
  .page-link:after,
.dataTables_wrapper
  .themeTable__pagination
  .dataTables_paginate
  ul.pagination
  .page-item:last-child
  .page-link:before,
.dataTables_wrapper
  .themeTable__pagination
  .dataTables_paginate
  ul.pagination
  .page-item:last-child
  .page-link:after {
  font-family: 'Nioicon' !important;
  font-size: 16px;
}

.dataTables_wrapper
  .themeTable__pagination
  .dataTables_paginate
  ul.pagination
  .page-item.previous
  .page-link:before {
  content: '';
}

.dataTables_wrapper
  .themeTable__pagination
  .dataTables_paginate
  ul.pagination
  .page-item.next
  .page-link:after {
  content: '';
}

.dataTables_wrapper
  .themeTable__pagination
  .dataTables_paginate
  ul.pagination
  .page-item.disabled
  .page-link {
  color: #56666d;
  border-color: #dce8eb;
}

@media screen and (max-width: 767px) {
  .pagination {
    justify-content: center !important;
  }
}

div.dataTables_wrapper
  div.dataTables_paginate
  ul.pagination
  li:not(.previous):not(.next) {
  display: block;
}

.nk-main .wrapper {
  height: 100%;
  width: 100%;
  position: relative;
}

.nk-main .wrapper:before {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.25);
  z-index: 1000;
  opacity: 0;
  transform: scale(0);
  transition: opacity 0.4s;
}

.nk-main .wrapper.visible:before {
  opacity: 1;
  transform: scale(1);
}

.nk-main .drawer {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 89%;
  height: 100vh;
  background-color: #fff;
  z-index: 1001;
  transform: translateX(100%);
  transition: transform 0.4s;
  overflow-y: auto;
}

@media screen and (min-width: 768px), print {
  .nk-main .drawer-sm {
    width: 416px;
  }
}

@media screen and (min-width: 768px), print {
  .nk-main .drawer-md {
    width: 500px;
  }
}

@media screen and (min-width: 768px), print {
  .nk-main .drawer-lg {
    width: 600px;
  }
}

.nk-main .drawer-title {
  margin-bottom: 24px;
}

.nk-main .drawer-content {
  height: 100% !important;
}

.nk-main .drawer-wrapper {
  position: relative;
  width: 100%;
  min-height: 100%;
  padding: 36px 30px 20px;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 767px) {
  .nk-main .drawer-wrapper {
    padding: 30px 20px 20px;
  }
}

.nk-main .drawer-actions {
  position: sticky;
  left: 0;
  top: auto;
  bottom: 0;
  border-top: 1px solid #dce8eb;
  width: calc(100% + 60px);
  height: 81px;
  z-index: 5;
  padding: 20px 30px 20px;
  margin: 0 -30px;
  background: #fff;
  margin-top: auto;
}

@media screen and (max-width: 767px) {
  .nk-main .drawer-actions {
    margin: auto -20px 0;
    width: calc(100% + 40px);
    padding: 15px 20px;
  }
}

@media screen and (max-width: 480px) {
  .nk-main .drawer-actions {
    height: 70px;
  }
}

.nk-main .drawer.show {
  transform: translateX(0);
  -ms-box-shadow: 0px 5px 56px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0px 5px 56px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 56px rgba(0, 0, 0, 0.1);
}

.nk-main .drawer .credit-card-input {
  border: 1px dashed #b3bfc5;
  border-style: dashed;
  border-radius: 10px;
  padding: 15px 20px 20px;
}

@media screen and (max-width: 480px) {
  .nk-main .drawer .credit-card-input {
    padding: 15px;
  }
}

.nk-main .drawer ::-webkit-scrollbar {
  width: 0px;
}

.nk-main .drawer--add-company .buttons {
  display: flex;
}

.nk-main .drawer--add-company .buttons .btn-primary {
  flex-grow: 1;
}

.nk-main .drawer-addInvoice .drawer-wrapper,
.nk-main .drawer-editInvoice .drawer-wrapper {
  padding-bottom: 0;
}

@media screen and (max-width: 480px) {
  .nk-main .drawer-addInvoice .drawer-actions .btn,
  .nk-main .drawer-editInvoice .drawer-actions .btn {
    font-size: 0.85rem;
    padding: 12px 8px;
  }
}

@media screen and (max-width: 480px) {
  .nk-main .drawer-addInvoice .drawer-actions .btn + .btn,
  .nk-main .drawer-editInvoice .drawer-actions .btn + .btn {
    margin-left: 8px;
  }
}

.nk-main .drawer--pay-invoice .form-group .due-amount {
  padding: 15px;
  border: 1px solid #dce8eb;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
}

.nk-main .drawer--pay-invoice .form-group .due-amount p {
  margin-bottom: 0;
  line-height: 1;
}

.nk-main .drawer--pay-invoice .form-group .custom-radio {
  width: 100%;
}

.nk-main .drawer--pay-invoice .form-group .custom-control-label {
  width: 100%;
}

.nk-main .drawer--pay-invoice .form-group .custom-control-label:before,
.nk-main .drawer--pay-invoice .form-group .custom-control-label:after {
  top: 50%;
  transform: translateY(-50%);
}

.nk-main .drawer--pay-invoice .form-group .custom-control-label .block-card {
  width: 100%;
}

.nk-main .drawer--pay-invoice .add-card,
.nk-main .drawer--pay-invoice .bank-details,
.nk-main .drawer--pay-invoice .saved-card {
  display: none;
  margin-bottom: 30px;
}

.nk-main .drawer--pay-invoice .add-card .form-group-cc {
  display: none;
}

.nk-main .drawer--pay-invoice .block-danger__card {
  padding: 15px;
}

.nk-main .drawer--pay-invoice .bank-details .custom-radio {
  margin-bottom: 30px;
}

.nk-main
  .drawer--pay-invoice
  .bank-details
  .custom-radio
  .custom-control-label
  span {
  display: block;
}

.nk-main
  .drawer--pay-invoice
  .bank-details
  .custom-radio
  .custom-control-label
  span:first-of-type {
  color: #2e3b44;
}

.nk-main
  .drawer--pay-invoice
  .bank-details
  .custom-radio
  .custom-control-label
  span:last-of-type {
  color: #56666d;
  margin-top: 6px;
}

#edit-client-drawer .select2-container--default .select2-selection--multiple {
  border-color: #dce8eb;
  min-height: 44px;
}

#edit-client-drawer
  .select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered {
  padding: 9px 16px 3px !important;
  line-height: 1.5 !important;
}

#edit-client-drawer
  .select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered
  .select2-selection__choice {
  margin: 0 8px 8px 0;
  display: flex;
  flex-direction: row-reverse;
  font-size: 1rem;
  color: #56666d;
  background-color: #dce8eb;
}

#edit-client-drawer
  .select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered
  .select2-selection__choice
  .select2-selection__choice__remove {
  margin: 0 0 0 8px;
  font-weight: 500;
  font-size: 1.2857rem;
}

.themeAlert.fade {
  background: rgba(0, 0, 0, 0.25);
}

.themeAlert .modal-header {
  justify-content: center;
  padding-top: 0;
}

.themeAlert .modal-dialog {
  max-width: 26.1429rem;
}

.themeAlert .modal-content {
  padding: 2.1429rem 2.5rem;
  text-align: center;
}

.themeAlert .modal-content p {
  line-height: 1.8571rem;
}

.themeAlert .modal-content p span {
  color: #155bdb;
}

.themeAlert .modal-content .btn {
  width: 100%;
}

.themeAlert .modal-iconWrap {
  width: 8rem;
  height: 8rem;
  margin: 2.1429rem 0 2.5rem;
  background-color: #cfe6fd;
  border-radius: 50%;
  display: inline-block;
  line-height: 4.7143rem;
}

.themeAlert .modal-iconWrap .icon {
  background: #4b88e9;
  width: 4.7143rem;
  height: 4.7143rem;
  left: 1.6429rem;
  top: 1.6429rem;
  border-radius: 50%;
}

.themeAlert .modal-header {
  border: none;
}

.modal-confirm .modal-dialog {
  width: auto;
  max-width: fit-content;
  max-width: -moz-fit-content;
  min-width: 24.2857rem;
  margin: 0 auto;
}

.modal-confirm .modal-content {
  border-radius: 0.7143rem;
}

@media screen and (max-width: 480px) {
  .modal-confirm .modal-content {
    margin: 0 10px;
  }
}

.modal-confirm .modal-body {
  padding: 2.1429rem 1.7143rem 1.7143rem;
}

.modal-confirm .modal-body h5 {
  margin-bottom: 0.2857rem;
}

.modal-confirm .modal-body p {
  margin-bottom: 1.4286rem;
  color: #2e3b44;
}

.modal-confirm .modal-body .text-info500 {
  color: #4b88e9;
}

.modal-confirm .modal-body .btn-info500 {
  background-color: #4b88e9;
  border: none;
  color: #fff;
}

.modal-confirm .modal-body .btn-text500 {
  background-color: #56666d;
  border: none;
  color: #fff;
}

@media screen and (max-width: 480px) {
  .modal-confirm .modal-body .btn {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .modal-confirm .modal-body .btn + .btn {
    margin-left: 0;
    margin-top: 10px;
  }
}

.ms-form {
  height: 100%;
  padding-bottom: 30px;
}

.ms-form__steps {
  margin: 0 -30px 30px;
}

@media screen and (max-width: 767px) {
  .ms-form__steps {
    margin: 0 -20px 20px;
  }
}

.ms-form__steps .nav {
  margin: 0;
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 1px solid #dce8eb;
}

.ms-form__steps-item {
  color: #56666d;
  padding: 0 6px 15px;
}

@media screen and (max-width: 767px) {
  .ms-form__steps-item {
    min-width: 20%;
    flex-grow: 1;
  }
}

@media screen and (min-width: 768px), print {
  .ms-form__steps-item {
    padding: 0 18px 15px;
  }

 
}



@media screen and (min-width: 1441px) {
  .ms-form__steps-item {
    padding: 0 20px 15px;
  }
}

.ms-form__steps-item:first-of-type {
  padding-left: 30px;
}

@media screen and (max-width: 767px) {
  .ms-form__steps-item:first-of-type {
    padding-left: 20px;
  }
}

@media screen and (min-width: 1023px) {
  .ms-form__steps-item:last-of-type {
    padding-right: 48px;
  }
}

.ms-form__steps-item:after {
  width: 0;
  height: 2px;
  left: 0;
  bottom: -1px;
  background-color: #3eb53e;
  transition: width 0.3s ease-in;
}

.ms-form__steps-item.active {
  color: #3eb53e;
}

.ms-form__steps-item.active .ms-form__steps-number {
  background: #f2fded;
  border-color: #2d9b36;
}

.ms-form__steps-item.active.ms-form__steps-item:after {
  width: 100%;
}

.ms-form__steps-item.active svg path {
  stroke: #3eb53e;
}

.ms-form__steps-link {
  color: inherit;
  line-height: 14px;
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.ms-form__steps-link [class*='icon-'] {
  margin: 0 4px 0 6px;
}

.ms-form__steps-link [class*='icon-'] + span {
  margin-left: 0;
}

.ms-form__steps-link:hover {
  color: inherit !important;
}

@media screen and (max-width: 480px) {
  .ms-form__steps-link span:last-of-type {
    display: none;
  }
}

.ms-form__steps-number {
  color: inherit;
  padding: 2px 8px;
  border: 1px solid #dce8eb;
  border-radius: 6px;
}

@media screen and (max-width: 767px) {
  .ms-form__steps-number {
    font-size: 0.85rem;
  }
}

.ms-form__content {
  padding-bottom: 30px;
}

.ms-form__content .btn-wrap {
  margin-top: 30px;
  flex-direction: row;
}

@media screen and (max-width: 480px) {
  .ms-form__content .btn-wrap {
    margin-top: 1.5rem;
  }

  .ms-form__content .btn-wrap .btn {
    width: 100%;
  }
}

.ms-form__content .form-icon {
  height: 44px;
  top: auto;
  transform: none;
}

.ms-form__content--client textarea {
  height: 100px;
}

.ms-form__content--item .item {
  width: 100%;
  border: 1px dashed #dce8eb;
  display: flex;
  border-radius: 5px;
  margin-bottom: 20px;
}

@media screen and (max-width: 480px) {
  .ms-form__content--item .item {
    flex-wrap: wrap;
  }
}

.ms-form__content--item .item__left {
  background: rgba(237, 244, 245, 0.5);
  padding: 16px 10px 0;
  flex-shrink: 0;
}

@media screen and (max-width: 480px) {
  .ms-form__content--item .item__left {
    flex-basis: 100%;
    padding: 10px 0;
    text-align: center;
  }
}

.ms-form__content--item .item__description {
  padding: 16px 14px 18px;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 480px) {
  .ms-form__content--item .item__description {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.ms-form__content--item .item__description .form-group {
  margin-bottom: 12px;
}

.ms-form__content--item .item__description .form-group:last-of-type {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  .ms-form__content--item .item__description .form-group {
    flex-basis: 100%;
  }
}

.ms-form__content--item .item__description .form-inline {
  flex-wrap: nowrap;
}

@media screen and (max-width: 767px) {
  .ms-form__content--item .item__description .form-inline {
    flex-wrap: wrap;
    flex-basis: 100%;
  }
}

.ms-form__content--item
  .item__description
  .form-inline
  .form-group
  .input-group {
  width: 189px;
}

@media screen and (max-width: 767px) {
  .ms-form__content--item
    .item__description
    .form-inline
    .form-group
    .input-group {
    width: 100%;
  }
}

.ms-form__content--item
  .item__description
  .form-inline
  .form-group:last-of-type
  .form-control {
  width: 184px;
}

.ms-form__content--item .item__description .form-inline .multiply {
  margin: 0 20px 0 14px;
  align-self: center;
}

@media screen and (max-width: 767px) {
  .ms-form__content--item .item__description .form-inline .multiply {
    flex-basis: 100%;
    text-align: center;
    margin: 8px 0;
  }
}

.ms-form__content--item .item__description .btn {
  height: 34px;
  width: 34px;
  margin-left: 10px;
}

@media screen and (max-width: 480px) {
  .ms-form__content--item .item__description .btn {
    margin-left: 0;
    margin-top: 10px;
  }
}

.ms-form__content--options .form-group--date .form-control {
  width: 280px;
}

@media screen and (max-width: 480px) {
  .ms-form__content--options .form-group--date .form-control {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .ms-form__content--options .form-group--discount > div {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start !important;
  }
}

.ms-form__content--options .form-group--discount .form-control-wrap {
  max-width: 252px;
}

@media screen and (max-width: 480px) {
  .ms-form__content--options .form-group--discount .form-control-wrap {
    max-width: 100%;
    width: 100%;
  }
}

.ms-form__content--options .form-group--discount #percent {
  display: none;
}

.ms-form__content--options .form-group--discount .show-percent #usd {
  display: none;
}

.ms-form__content--options .form-group--discount .show-percent #percent {
  display: block;
}

.ms-form__content--options .form-group--discount .discount-option {
  cursor: pointer;
  color: #155bdb;
  margin-left: 14px;
}

@media screen and (max-width: 480px) {
  .ms-form__content--options .form-group--discount .discount-option {
    margin: 10px 0 0 0;
  }
}

.ms-form__content--summary .nk-block {
  margin-bottom: 24px;
}

@media screen and (max-width: 767px) {
  .ms-form__content--summary .nk-block .card-stretch {
    margin: 0;
  }
}

.ms-form__content--summary .invoice-summary {
  overflow: hidden;
  box-shadow: none;
}

.ms-form__content--summary .invoice-summary .card-inner:first-of-type {
  padding: 23px 24px 18px 20px;
  background: #f6fafa;
}

@media screen and (max-width: 767px) {
  .ms-form__content--summary .invoice-summary .card-inner:first-of-type {
    padding: 16px 14px;
  }
}

.ms-form__content--summary .invoice-summary .nk-ibx-head {
  padding: 0;
  min-height: 45px;
  border-bottom: none;
}

.ms-form__content--summary .invoice-summary .nk-ibx-head .nk-ibx-item {
  padding: 21px 0 9px;
}

@media screen and (max-width: 480px) {
  .ms-form__content--summary .invoice-summary .nk-ibx-head .nk-ibx-item {
    padding: 10px 0;
  }
}

.ms-form__content--summary .invoice-summary .nk-ibx-body {
  max-width: 100% !important;
}

.ms-form__content--summary .invoice-summary .nk-ibx-body .currency {
  font-size: 0.8571rem;
  line-height: 12px;
  color: #56666d;
}

.ms-form__content--summary .invoice-summary .nk-ibx-list {
  min-height: 172px;
}

.ms-form__content--summary .invoice-summary .nk-ibx-list .nk-ibx-item {
  padding: 16px 0;
}

@media screen and (max-width: 480px) {
  .ms-form__content--summary .invoice-summary .nk-ibx-list .nk-ibx-item {
    padding: 10px 0;
    font-size: 1rem;
  }
}

.ms-form__content--summary .invoice-summary .nk-ibx-list .nk-ibx-item-elem {
  font-size: 1.0714rem;
  line-height: 15px;
  color: #2e3b44;
}

.ms-form__content--summary .invoice-summary .nk-ibx-list .nk-ibx-item-elem .tb-qty{
  display: inline-block;
}

.ms-form__content--summary
  .invoice-summary
  .nk-ibx-list
  .nk-ibx-item-elem:first-of-type {
  padding: 0 0 0 4px;
  text-align: right;
}

.ms-form__content--summary .invoice-summary .nk-ibx-item {
  width: 100%;
  border-bottom: 1px solid #dce8eb;
}

.ms-form__content--summary .invoice-summary .nk-ibx-item:hover {
  background: none;
}

.ms-form__content--summary .invoice-summary .nk-ibx-item .sub-text {
  color: #56666d;
  font-size: 1rem;
  line-height: 14px;
  opacity: 0.8;
}

.ms-form__content--summary .invoice-summary .nk-ibx-item-elem {
  padding: 0 5px;
}

.ms-form__content--summary .invoice-summary .nk-ibx-item-elem:first-of-type {
  width: 6%;
}

@media screen and (max-width: 480px) {
  .ms-form__content--summary .invoice-summary .nk-ibx-item-elem:first-of-type {
    width: 7%;
  }
}

.ms-form__content--summary .invoice-summary .nk-ibx-item-elem:nth-of-type(2) {
  width: 55%;
}

@media screen and (max-width: 480px) {
  .ms-form__content--summary .invoice-summary .nk-ibx-item-elem:nth-of-type(2) {
    width: 42%;
  }
}

.ms-form__content--summary .invoice-summary .nk-ibx-item-elem:nth-of-type(3) {
  width: 22%;
}

@media screen and (max-width: 480px) {
  .ms-form__content--summary .invoice-summary .nk-ibx-item-elem:nth-of-type(3) {
    width: 35%;
  }
}

.ms-form__content--summary .invoice-summary .nk-ibx-item-elem:last-of-type {
  width: 17%;
}

@media screen and (max-width: 480px) {
  .ms-form__content--summary .invoice-summary .nk-ibx-item-elem:last-of-type {
    width: 16%;
  }
}

.ms-form__content--summary .invoice-summary .nk-ibx-footer {
  background: #f6fafa;
  height: 54px;
  border-top: 1px solid #dce8eb;
}

.ms-form__content--summary .invoice-summary .nk-ibx-footer .nk-ibx-item {
  padding: 17px 5px 13px;
  background: #f6fafa;
  border-bottom: 0;
}

.ms-form__content--summary .invoice-summary .nk-ibx-footer .tb-amount {
  font-size: 1rem;
  line-height: 20px;
  display: inline-block;
}

.ms-form__content--summary .invoice-summary__info {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.ms-form__content--summary .invoice-summary__info .btn-xs {
  height: 36px;
  width: 36px;
  margin-right: 10px;
}

.ms-form__content--summary .invoice-summary__info .text span {
  display: block;
}

.ms-form__content--summary .invoice-summary__info .text span:first-of-type {
  color: #56666d;
  margin-bottom: 4px;
  line-height: 14px;
  opacity: 0.8;
}

.ms-form__content--summary .invoice-summary__info .text span:last-of-type {
  color: #2e3b44;
  line-height: 15px;
}

@media screen and (max-width: 480px) {
  .ms-form__content--summary .invoice-summary__info .right {
    margin-top: 15px;
    display: flex;
  }
}

.ms-form__content--summary .invoice-summary__info .right span:first-of-type {
  margin: 0 8px 0 0;
}

@media screen and (max-width: 480px) {
  .ms-form__content--summary .invoice-summary__info {
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
  }
  .invoice-summary__info > div:nth-child(1) > div:nth-child(2) {
    bottom: -7px;
    position: relative;
   }
   .ms-form__content--summary .invoice-summary__info .text span {
    display: inline-block;
    margin-right: 10px;
  }   
}

.ms-form__content--summary .form-group .custom-switch {
  min-height: 24px;
}

.ms-form__content--summary .form-group .custom-switch .custom-control-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  vertical-align: middle;
}

.ms-form__content--summary .form-group .custom-switch span {
  display: block;
}

.ms-form__content--summary .form-group .custom-switch span:first-of-type {
  opacity: 0.6;
  margin-bottom: 0;
}

.ms-form__content--summary .form-group .custom-switch span:last-of-type {
  color: #8096a0;
  margin-top: 6px;
}

.ms-form__content--summary .saved-card {
  display: none;
  transition: all 0.5s;
  margin: 20px 0 24px;
}

.ms-form__content--summary .saved-card.show {
  display: block;
}

.daterangepicker {
  padding: 17px;
  position: absolute;
  color: inherit;
  background-color: #ffffff;
  border-radius: 10px;
  max-width: none;
  margin-top: 7px;
  top: 100px;
  left: 20px;
  z-index: 3001;
  display: none;
  font-size: 14px;
  line-height: 1em;
  -ms-box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 767px) {
  .daterangepicker {
    width: calc(100% - 30px);
  }
}

@media screen and (min-width: 768px), print {
  .daterangepicker {
    padding: 20px 20px 25px;
  }
}

.daterangepicker tr {
  border: solid #fff;
}

.daterangepicker.opensleft:before {
  right: 9px;
}

.daterangepicker.opensleft:after {
  right: 10px;
}

.daterangepicker.openscenter:before {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.openscenter:after {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.opensright:before {
  left: 9px;
}

.daterangepicker.opensright:after {
  left: 10px;
}

.daterangepicker.drop-up {
  margin-top: -7px;
}

.daterangepicker.drop-up:before {
  top: initial;
  bottom: -7px;
  border-bottom: initial;
  border-top: 7px solid #cccccc;
}

.daterangepicker.drop-up:after {
  top: initial;
  bottom: -6px;
  border-bottom: initial;
  border-top: 6px solid #ffffff;
}

.daterangepicker.single .daterangepicker .ranges,
.daterangepicker.single .drp-calendar {
  float: none;
}

.daterangepicker.single .drp-selected {
  display: none;
}

.daterangepicker.show-calendar .drp-calendar {
  display: block;
}

.daterangepicker.show-calendar .drp-buttons {
  display: none;
}

.daterangepicker.auto-apply .drp-buttons {
  display: none;
}

.daterangepicker .drp-calendar {
  display: none;
}

.daterangepicker .drp-calendar.left {
  padding: 0 15px 3px 0;
}

@media screen and (max-width: 991px) {
  .daterangepicker .drp-calendar.left {
    padding-right: 5px;
  }
}

.daterangepicker .drp-calendar.left .calendar-table {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.daterangepicker .drp-calendar.left .calendar-table {
  padding-right: 8px;
}

.daterangepicker .drp-calendar.right {
  padding: 0 0 3px 15px;
}

@media screen and (max-width: 991px) {
  .daterangepicker .drp-calendar.right {
    padding-left: 5px;
  }
}

.daterangepicker .drp-calendar.right .calendar-table {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.daterangepicker .drp-calendar.single .calendar-table {
  border: none;
}

.daterangepicker .drp-calendar.left {
  clear: left;
  margin-right: 0;
}

.daterangepicker .drp-calendar.right {
  margin-left: 0;
}

.daterangepicker .drp-calendar.left {
  clear: none;
}

.daterangepicker .calendar-table .next span:after,
.daterangepicker .calendar-table .prev span:after {
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid #56666d;
}

.daterangepicker .calendar-table .next span:after {
  transform: rotate(180deg);
}

.daterangepicker .calendar-table .prev:before,
.daterangepicker .calendar-table .next:before {
  height: 32px;
  width: 32px;
  border: 1px solid #dce8eb;
  background: #edf4f5;
  border-radius: 10px;
  opacity: 1;
  transform: translate(-50%, -54%);
}

.daterangepicker .calendar-table .prev:hover:before,
.daterangepicker .calendar-table .next:hover:before {
  border: 1px solid #3eb53e;
  background: #f2fded;
}

.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  min-width: 32px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 1rem;
  border-radius: 50%;
  cursor: pointer;
}

@media screen and (max-width: 991px) {
  .daterangepicker .calendar-table th,
  .daterangepicker .calendar-table td {
    font-size: 0.875rem;
  }
}

.daterangepicker .calendar-table th {
  padding: 2px 5px;
  color: #56666d;
}

@media screen and (min-width: 992px), print {
  .daterangepicker .calendar-table th {
    padding: 2px 10px;
  }
}

.daterangepicker .calendar-table td {
  color: #2e3b44;
  padding: 4px 16px;
}

@media screen and (max-width: 991px) {
  .daterangepicker .calendar-table td {
    padding: 2.5px 10px;
  }
}

.daterangepicker .calendar-table {
  border: 1px solid #ffffff;
  border-radius: 4px;
  background-color: #ffffff;
}

.daterangepicker .calendar-table table {
  width: 100%;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse;
}

.daterangepicker td.available,
.daterangepicker th.available {
  z-index: 1;
}

.daterangepicker td.available:before,
.daterangepicker th.available:before {
  border-color: #3eb53e;
  background-color: #3eb53e;
  width: 30px;
  height: 30px;
  z-index: -1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: 0.2s ease;
  border-radius: 50%;
}

.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  color: #fff;
}

.daterangepicker td.available:hover:before,
.daterangepicker th.available:hover:before {
  opacity: 1;
}

.daterangepicker td.available.start-date,
.daterangepicker th.available.start-date {
  z-index: 5;
}

.daterangepicker td.available.start-date:hover,
.daterangepicker th.available.start-date:hover {
  z-index: 5;
}

.daterangepicker td.available.start-date + .in-range:after,
.daterangepicker th.available.start-date + .in-range:after {
  height: 30px;
  width: 90%;
  background: #edf4f5;
  position: absolute;
  left: -50%;
  top: 50%;
  transform: translateY(-50%);
}

.daterangepicker td.available.in-range,
.daterangepicker th.available.in-range {
  color: #2e3b44;
}

.daterangepicker td.week,
.daterangepicker th.week {
  font-size: 80%;
  color: #cccccc;
}

.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
  background-color: #ffffff;
  color: #b3bfc5;
}

.daterangepicker td.in-range {
  color: #000000;
  border-radius: 0;
}

.daterangepicker td.in-range:before,
.daterangepicker td.in-range.today:before {
  height: 30px;
  width: 101%;
  top: 50%;
  transform: translateY(-50%);
  background: #edf4f5;
  border-color: #edf4f5;
  opacity: 1;
  border-radius: 0;
  left: 0;
}

.daterangepicker td.start-date {
  border-radius: 4px 0 0 4px;
}

.daterangepicker td.start-date.end-date {
  border-radius: 4px;
}

.daterangepicker td.start-date:after {
  display: none;
}

.daterangepicker td.end-date {
  border-radius: 0 4px 4px 0;
}

.daterangepicker td.end-date:after {
  height: 30px;
  width: 50%;
  top: 50%;
  transform: translateY(-50%);
  background: #edf4f5;
  border-color: #edf4f5;
  opacity: 1;
  border-radius: 0;
  left: 0%;
  z-index: -2;
}

.daterangepicker td.today {
  color: #2e3b44;
}

.daterangepicker td.today:before {
  border: 1px solid #dce8eb;
  background-color: #f6fafa;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  z-index: -1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  color: #ffffff !important;
  z-index: 1;
}

.daterangepicker td.active:before,
.daterangepicker td.active.today.in-range:before,
.daterangepicker td.active:hover:before,
.daterangepicker td.active:hover.today.in-range:before {
  border-color: #3eb53e !important;
  background-color: #3eb53e !important;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  z-index: -1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
}

.daterangepicker th.month {
  width: auto;
}

.daterangepicker td.disabled,
.daterangepicker option.disabled {
  color: #999999;
  cursor: not-allowed;
  text-decoration: line-through;
}

.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
  font-size: 14px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default;
  color: #56666d;
}

.daterangepicker select.monthselect {
  width: 60px;
}

.daterangepicker select.yearselect {
  width: 65px;
}

.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
  width: 50px;
  margin: 0 auto;
  background: #eeeeee;
  border: 1px solid #eeeeee;
  padding: 2px;
  outline: 0;
  font-size: 12px;
}

.daterangepicker .calendar-time {
  text-align: center;
  margin: 4px auto 0 auto;
  line-height: 30px;
  position: relative;
}

.daterangepicker .calendar-time select.disabled {
  color: #cccccc;
  cursor: not-allowed;
}

.daterangepicker .drp-buttons {
  clear: both;
  text-align: right;
  padding: 8px;
  display: none;
  line-height: 12px;
  vertical-align: middle;
}

.daterangepicker .drp-buttons .btn {
  margin-left: 8px;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px;
}

.daterangepicker .drp-selected {
  display: inline-block;
  font-size: 12px;
  padding-right: 8px;
}

.daterangepicker.show-ranges.single.rtl .drp-calendar.left {
  border-right: 1px solid #dddddd;
}

.daterangepicker.show-ranges.rtl .drp-calendar.right {
  border-right: 1px solid #dddddd;
}

.daterangepicker .ranges {
  float: none;
  text-align: left;
  margin: 0;
}

.daterangepicker .ranges ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 991px) {
  .daterangepicker .ranges ul {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.daterangepicker .ranges li {
  display: inline-block;
  font-size: 12px;
  line-height: 10px;
  height: 32px;
  padding: 10px 16px;
  border-radius: 58px;
  border: 1px solid #dce8eb;
  cursor: pointer;
  margin-right: 8px;
}

.daterangepicker .ranges li:hover,
.daterangepicker .ranges li.active {
  background-color: #f2fded;
  color: #2d9b36;
  border-color: #3eb53e;
}

.daterangepicker .ranges li:last-child {
  display: none;
}

@media screen and (max-width: 767px) {
  .daterangepicker .ranges li {
    margin: 5px 5px 5px 0;
  }
}

@media screen and (max-width: 480px) {
  .daterangepicker .ranges li {
    width: 48%;
    text-align: center;
  }
}

.daterangepicker .ranges ul {
  width: 100%;
}

.daterangepicker.show-calendar .ranges {
  margin-top: 7px;
}

.daterangepicker.single .ranges ul {
  width: 100%;
}

.daterangepicker.single .drp-calendar.left {
  clear: none;
}

.daterangepicker.single .ranges,
.daterangepicker.single .drp-calendar {
  float: left;
}

.daterangepicker .ranges,
.daterangepicker .drp-calendar {
  float: left;
}

.daterangepicker .ranges {
  float: left;
}

.daterangepicker.rtl .ranges {
  float: right;
}

@media (min-width: 564px) {
  .daterangepicker {
    width: auto;
  }
}

.daterangepicker .daterangepicker {
  direction: ltr;
  text-align: left;
}

@media (min-width: 730px) {
  .daterangepicker {
    width: auto;
  }
}

select.customselect {
  border: none;
  appearance: none;
  background-position: calc(101% - 6px) 50%;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-image: url(../images/icon-down.svg);
  -moz-appearance: none;
  -webkit-appearance: none;
}

select.customselect.yearselect {
  background-position: calc(101% - 8px) 50%;
}

.daterange-wrapper {
  display: flex;
  overflow: auto;
}

.colorpicker {
  background: #fff;
  max-width: 226px;
  padding: 15px;
  filter: drop-shadow(0px 4px 36px rgba(0, 0, 0, 0.1));
  border-radius: 5px;
  display: none;
  cursor: pointer;
  top: 55%;
  z-index: 5;
}

@media screen and (max-width: 480px) {
  .colorpicker {
    top: 110%;
  }
}

@media screen and (min-width: 1441px) {
  .colorpicker {
    left: calc(100% + 48px);
    top: 0;
  }
}

.colorpicker.visible {
  display: block;
}

.colorpicker .hue {
  width: 100%;
  height: 8px;
  border-radius: 7px;
  margin: 16px 0;
  background: linear-gradient(
    to right,
    red 0,
    #ff0 17%,
    lime 33%,
    cyan 50%,
    blue 67%,
    #f0f 83%,
    red
  );
}

.colorpicker .hue .drag {
  --x: 0;
  width: 12px;
  height: 12px;
  top: -2px;
  bottom: -2px;
  position: absolute;
  transform: translate(var(--x), 0);
}

.colorpicker .hue .drag:before {
  width: 100%;
  height: 100%;
  border: 2px solid #fff;
  position: absolute;
  right: 0;
  border-radius: 50%;
}

.colorpicker .field {
  --backgroundHue: 150;
  position: relative;
  background: hsl(var(--backgroundHue), 100%, 50%);
  height: 112px;
  width: 125px;
}

.colorpicker .field .fieldOverlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, transparent, #000),
    linear-gradient(to right, #fff, transparent);
}

.colorpicker .field .fieldOverlay .drag {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  left: -5px;
  top: -5px;
  border: 2px solid #fff;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.8);
  transform: translate(var(--x), var(--y));
}

.colorpicker input {
  background: #f6fafa;
  outline: none;
  border: 1px solid #dce8eb;
  font-family: inherit;
  padding: 10px;
  display: table;
  text-align: left;
  width: 100%;
  color: #56666d;
  font-size: 1rem;
  min-height: 32px;
}

.colorpicker ul {
  padding-left: 15px;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.colorpicker ul li {
  font-size: 0.8571rem;
  line-height: 12px;
  text-align: center;
  width: 55px;
  color: #56666d;
  background-color: #f6fafa;
  border: 1px solid #dce8eb;
  border-radius: 5px;
  display: flex;
  padding: 8px 6px;
}

.colorpicker ul li strong {
  display: block;
  color: #182239;
  margin: 0 0 0 8px;
}

.notification__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 16px;
}

.notification__header .btn-plaintext {
  font-weight: 500;
}

.notification__img {
  height: 32px;
  min-width: 32px;
  border-radius: 8px;
  border: 1px solid #3eb53e;
  margin-right: 12px;
}

.notification__img img {
  height: 16px;
  width: 16px;
}

.notification__img.received {
  border-color: #3eb53e;
  background: #f2fded;
}

.notification__img.opened {
  border-color: #dce8eb;
  background: #fff;
}

.notification__block-head {
  background: #edf4f5;
  padding: 6px 30px;
  margin: 0 -30px;
}

@media screen and (max-width: 767px) {
  .notification__block-head {
    padding: 6px 20px;
    margin: 0 -20px;
  }
}

.notification__block-head span {
  font-size: 0.8571rem;
  line-height: 1;
  display: block;
  color: #2e3b44;
}

.notification__block-list-item {
  padding: 13px 0;
  display: flex;
  align-items: center;
}

.notification__block-list-item:first-of-type {
  padding-top: 16px;
}

.notification__block-list-item:not(:last-of-type) {
  border-bottom: 1px solid #dce8eb;
}

.notification__block-list-item.status-unread:after {
  background: #f6fafa;
  height: 100%;
  width: calc(100% + 60px);
  left: -30px;
  z-index: -1;
}

@media screen and (max-width: 767px) {
  .notification__block-list-item.status-unread:after {
    width: calc(100% + 40px);
    left: -20px;
  }
}

.notification__block-list-item.status-unread::before {
  height: 6px;
  width: 6px;
  border-radius: 6px;
  background: #3eb53e;
  left: -18px;
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (max-width: 767px) {
  .notification__block-list-item.status-unread::before {
    left: -14px;
  }
}

.notification__block-list-item .ht-title {
  color: #2e3b44;
}

.notification__block-list-item .ht-date {
  color: #56666d;
}

.notification__block-list-item p {
  color: #56666d;
  font-size: 1rem;
  line-height: 1.6;
}

.notification__block-list-item p a::after {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  cursor: pointer;
}

.saved-card .title {
  font-size: 1rem;
  line-height: 1;
  color: #56666d;
  margin-bottom: 6px;
}

.saved-card .block-card__item {
  padding: 15px 14px;
  background: #f0f5f9;
  border-radius: 6px;
  display: flex;
}

.saved-card .block-card__item .tag {
  background: #fff;
  border-radius: 5px;
  height: 40px;
  border: none;
}

.saved-card .block-card__text {
  margin-left: 14px;
}

.saved-card .block-card__text span {
  display: block;
  line-height: 14px;
}

.saved-card .block-card__text span#expiry-date {
  color: #56666d;
  margin-top: 5px;
}

.saved-card .block-card .btn {
  margin-left: auto;
}

.nav-tabs {
  border-color: #dce8eb;
  margin: 0 -15px 0 -20px;
  padding: 0 15px 0 20px;
}

@media screen and (min-width: 1441px) {
  .nav-tabs {
    margin: 0 -30px;
    padding: 0 30px;
  }
}



.nav-tabs .nav-item {
  padding-right: 40px;
  padding-bottom: 5px !important;
}

.nav-tabs .nav-link {
  font-size: 1.0714rem;
  line-height: 1;
  padding: 0 0 16.5px;
}

.nav-tabs .nav-link.active {
  color: #2d9b36;
}

.nav-tabs .nav-link:after {
  height: 2px;
  background: #2d9b36;
}

.nav-tabs .nav-link:hover {
  color: #2d9b36;
}

#header {
  min-height: 68px;
}


@media screen and (min-width: 1441px) {
  #header {
    min-height: 70px;
  }
}

.invoice-cards {
  padding-bottom: 21px;
  min-height: 78px;
}

@media screen and (min-width: 1441px) {
  .invoice-cards {
    min-height: 98px;
  }
}

.invoice-cards ul {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 991px) {
  .invoice-cards ul {
    flex-wrap: wrap;
    margin: 0 -7.5px;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 480px) {
  .invoice-cards ul {
    margin: 0;
  }
}

.invoice-cards ul li {
  width: 100%;
  margin-bottom: 14px;
}

@media screen and (min-width: 481px), print {
  .invoice-cards ul li {
    width: calc((100% - 30px) / 2);
    margin: 0 7.5px 14px;
  }
}

@media screen and (min-width: 768px), print {
  .invoice-cards ul li {
    width: calc((100% - 45px) / 3);
  }
}

@media screen and (min-width: 992px), print {
  .invoice-cards ul li {
    width: calc((100% - 40px) / 5);
    margin: 0;
  }
}

@media screen and (min-width: 1441px) {
  .invoice-cards ul li {
    width: calc((100% - 80px) / 5);
  }
}

.datatable-wrap {
  border: none;
  padding: 15px 0 0;
}

@media screen and (min-width: 768px), print {
  .datatable-wrap {
    padding: 18px 0 0;
  }
}

.clients .datatable-wrap,
.members .datatable-wrap {
  padding: 15px 0 0;
}

@media screen and (min-width: 992px), print {
  .clients .datatable-wrap,
  .members .datatable-wrap {
    padding: 18px 0 0;
  }
}

.dataTables_wrapper {
  padding-top: 9px;
}

@media screen and (max-width: 767px) {
  .dataTables_filter {
    width: 100%;
  }
}

.dataTables_filter .form-control {
  min-height: 40px;
  background: #edf4f5;
  min-width: 364px;
  border-radius: 10px;
  /* border: 1px solid #dce8eb; */
  border: none;
  padding-left: 40px;
  color: #56666d;
}

.dataTables_filter .form-control:focus{
  background: rgb(255, 255, 255);
  border: 1px solid #c4c5c5 !important;
  box-shadow: none;
  outline: 0;
}
.dataTables_filter .form-control:focus::placeholder{
  opacity: 0.5;
}

.dataTables_filter .form-control::placeholder {
  color: #696d6d;
}

@media screen and (max-width: 382px) {
  .dataTables_filter .form-control {
    min-width: 300px;
    max-width: 100%;
    width: 100%;
  }
}

.dataTables_filter label {
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .dataTables_filter label {
    width: 100%;
  }
}

.dataTables_length {
  display: none;
}

.themeTable {
  color: #2e3b44;
  margin: 0;
  overflow: auto;
  table-layout: fixed;
  background: #fff;
  width: 100% !important;
}

.themeTable thead th {
  padding: 12px 0 12px 0;
  border: 0 solid #dce8eb;
  border-bottom-width: 0 !important;
}

.themeTable thead th:first-child {
  padding-left: 9px;
}

@media screen and (max-width: 480px) {
  .themeTable thead th:first-child {
    padding-left: 16px;
  }
}

@media screen and (min-width: 1023px) {
  .themeTable thead th {
    padding: 12px 0 12px 0;
  }
}

.themeTable thead .sorting_asc,
.themeTable thead .sorting_desc {
  color: #2e3b44;
}

.themeTable thead tr th:before,
.themeTable thead tr th:after,
.themeTable thead .sorting_asc:before,
.themeTable thead .sorting_asc:after,
.themeTable thead .sorting_desc:before,
.themeTable thead .sorting_desc:after,
.themeTable thead .sorting_asc_disabled:before,
.themeTable thead .sorting_asc_disabled:after,
.themeTable thead .sorting_desc_disabled:before,
.themeTable thead .sorting_desc_disabled:after {
  position: absolute;
  font-family: 'Nioicon' !important;
  font-size: 0.7143rem;
  /* display: block; */
  transform: translateY(-50%);
}

.themeTable thead .sorting_asc:before{
  color: #c9c9c9;
}

.themeTable thead .sorting_desc:after{
  color: #c9c9c9;
} 

.themeTable thead .sorting_asc:before,
.themeTable thead .sorting_desc:after {
  color: #29e103  !important;
}


.themeTable thead .sorting:before,
.themeTable thead .sorting_asc:before,
.themeTable thead .sorting_desc:before,
.themeTable thead .sorting_asc_disabled:before,
.themeTable thead .sorting_desc_disabled:before {
  content: '' !important;
  top: 22px !important;
  right: 20px !important;
}

.themeTable thead .sorting:after,
.themeTable thead .sorting_asc:after,
.themeTable thead .sorting_desc:after,
.themeTable thead .sorting_asc_disabled:after,
.themeTable thead .sorting_desc_disabled:after {
  content: '' !important;
  top: 15px !important;
  right: 20px !important;
}

.themeTable thead .sorting:after,
.themeTable thead .sorting:before{
  opacity: 0.3;
}


.themeTable tbody td {
  border: solid #dce8eb;
  border-width: 0 0 1px 0;
  padding: 14.5px 12px 14.5px 0;
  vertical-align: middle;
  font-size: 1.0714rem;
}

@media screen and (max-width: 480px) {
  .themeTable tbody td{
    font-size: 0.8125rem;
    padding: 14.5px 10px 14.5px 0;
  }
}

@media screen and (min-width:481px) and (max-width: 1199px) {
  .themeTable tbody td{
    font-size: 0.9286rem;
    padding: 14.5px 10px 14.5px 0;
  }
}

/* @media screen and (max-width: 991px) {
  .themeTable tbody td{
    font-size: 0.7286rem;
    padding: 14.5px 8px 14.5px 0;
  }
} */


.themeTable tbody tr {
  transition: all 0.5s ease;
  border: 1px solid #dce8eb;
  background: #fff;
}
/* 
.themeTable tbody tr:hover{
  background-color: #f6f7fa;
} */
.themeTable tbody tr:nth-child(even):hover,.themeTable tbody tr:nth-child(odd):hover, .themeTable tbody tr:hover~td:first-child {
    background: #f6f7fa;
}

.themeTable tbody tr:nth-child(even) {
  background: rgba(246, 247, 250, 0.3);
}

.themeTable tbody tr:nth-child(even):hover {
  background: #f6f7fa;
}


.themeTable
  tbody
  tr:last-child
  .tool__tip:hover
  .themeTable
  tbody
  tr:last-child
  .tool__tip__content {
  top: 100%;
}

.themeTable tbody tr:last-child .tool__tip__content {
  top: -95%;
}

.themeTable tbody tr.hover {
  background: #f6f7fa;
}

.themeTable tbody tr td:first-child {
  border-left-width: 1px;
  padding-left: 16px;
}

@media screen and (max-width: 480px) {
  .themeTable tbody tr td:first-child {
    padding-left: 10px;
  }
}

.themeTable tbody tr td:last-child {
  border-right-width: 1px;
}

.themeTable tbody tr:first-child th,
.themeTable tbody tr:first-child td {
  border-top-width: 1px;
}

.themeTable tbody tr:first-child th:first-child,
.themeTable tbody tr:first-child td:first-child {
  border-top-left-radius: 10px;
}

.themeTable tbody tr:first-child th:last-child,
.themeTable tbody tr:first-child td:last-child {
  border-top-right-radius: 10px;
}

.themeTable tbody tr:last-child th,
.themeTable tbody tr:last-child td {
  border-bottom-width: 1px;
}

.themeTable tr:last-child th:first-child,
.themeTable tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

.themeTable tr:last-child th:last-child,
.themeTable tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.themeTable__title {
  color: #56666d;
  padding: 15px;
}

.themeTable__title-actions {
  width: 220px !important;
}

.themeTable__title-actions.sorting:before,
.themeTable__title-actions.sorting:after,
.themeTable__title-actions.sorting_asc:before,
.themeTable__title-actions.sorting_asc:after,
.themeTable__title-actions.sorting_desc:before,
.themeTable__title-actions.sorting_desc:after,
.themeTable__title-actions.sorting_asc_disabled:before,
.themeTable__title-actions.sorting_asc_disabled:after,
.themeTable__title-actions.sorting_desc_disabled:before,
.themeTable__title-actions.sorting_desc_disabled:after {
  display: none !important;
}

@media screen and (min-width: 1199px) {
  .themeTable__title-actions {
    width: 280px !important;
  }
}

.themeTable__title-members {
  min-width: 170px !important;
}

@media screen and (min-width: 768px), print {
  .themeTable__title-members {
    width: 25% !important;
  }
}

@media screen and (min-width: 1199px) {
  .themeTable__title-members {
    width: 262px !important;
  }
}

.themeTable__title-email {
  width: 350px !important;
}

@media screen and (min-width: 768px), print {
  .themeTable__title-email {
    width: 35% !important;
  }
}

@media screen and (min-width: 1199px) {
  .themeTable__title-email {
    width: 500px !important;
  }
}

@media screen and (min-width: 1600px) {
  .themeTable__title-email {
    width: 896px !important;
  }
}

.themeTable__title-memberStatus {
  width: 110px !important;
}

@media screen and (min-width: 768px), print {
  .themeTable__title-memberStatus {
    width: 20% !important;
  }
}

@media screen and (min-width: 1199px) {
  .themeTable__title-memberStatus {
    width: 144px !important;
  }
}

.themeTable__title-memberActions {
  width: 160px !important;
}

.themeTable__title-memberActions.sorting:before,
.themeTable__title-memberActions.sorting:after,
.themeTable__title-memberActions.sorting_asc:before,
.themeTable__title-memberActions.sorting_asc:after,
.themeTable__title-memberActions.sorting_desc:before,
.themeTable__title-memberActions.sorting_desc:after,
.themeTable__title-memberActions.sorting_asc_disabled:before,
.themeTable__title-memberActions.sorting_asc_disabled:after,
.themeTable__title-memberActions.sorting_desc_disabled:before,
.themeTable__title-memberActions.sorting_desc_disabled:after {
  display: none !important;
}

@media screen and (min-width: 768px), print {
  .themeTable__title-memberActions {
    width: 20% !important;
  }
}

@media screen and (min-width: 1199px) {
  .themeTable__title-memberActions {
    width: 310px !important;
  }
}

.themeTable__title-card {
  width: 140px !important;
}

@media screen and (min-width: 481px), print {
  .themeTable__title-card {
    width: 160px !important;
  }
}

@media screen and (min-width: 1023px) {
  .themeTable__title-card {
    width: 212px !important;
  }
}

.themeTable__title-invoice {
  width: 600px !important;
}

@media screen and (min-width: 1023px) {
  .themeTable__title-invoice {
    width: 740px !important;
  }
}

.themeTable__title-client {
  width: 170px !important;
}

@media screen and (min-width: 481px), print {
  .themeTable__title-client {
    width: 200px !important;
  }
}

@media screen and (min-width: 1023px) {
  .themeTable__title-client {
    width: 396px !important;
  }
}

.themeTable__title-status {
  width: 140px !important;
}

@media screen and (min-width: 481px), print {
  .themeTable__title-status {
    width: 200px !important;
  }
}

@media screen and (min-width: 1199px) {
  .themeTable__title-status {
    width: 210px !important;
  }
}

.themeTable__title-customer {
  width: 140px !important;
}

@media screen and (min-width: 481px), print {
  .themeTable__title-customer {
    width: 200px !important;
  }
}

@media screen and (min-width: 1199px) {
  .themeTable__title-customer {
    width: 244px !important;
  }
}

.themeTable__title-invoiceNo {
  width: 130px !important;
  /* width: auto; */
}

@media screen and (min-width: 1199px) {
  .themeTable__title-invoiceNo {
    width: 150px !important;
  }
}

.themeTable__title-date {
  width: 140px !important;
}

@media screen and (min-width: 1199px) {
  .themeTable__title-date {
    width: 160px !important;
  }
}

.themeTable__title-dueDate {
  width: 120px !important;
}

@media screen and (min-width: 1199px) {
  .themeTable__title-dueDate {
    width: 150px !important;
  }
}

.themeTable__title-amount {
  width: 140px !important;
}

@media screen and (min-width: 1199px) {
  .themeTable__title-amount {
    width: 160px !important;
  }
}

.themeTable__title-clientactions {
  width: 200px !important;
}

.themeTable__title-clientactions.sorting:before,
.themeTable__title-clientactions.sorting:after,
.themeTable__title-clientactions.sorting_asc:before,
.themeTable__title-clientactions.sorting_asc:after,
.themeTable__title-clientactions.sorting_desc:before,
.themeTable__title-clientactions.sorting_desc:after,
.themeTable__title-clientactions.sorting_asc_disabled:before,
.themeTable__title-clientactions.sorting_asc_disabled:after,
.themeTable__title-clientactions.sorting_desc_disabled:before,
.themeTable__title-clientactions.sorting_desc_disabled:after {
  display: none !important;
}

@media screen and (min-width: 1023px) {
  .themeTable__title-clientactions {
    width: 264px !important;
  }
}

.themeTable__title-reportMembers {
  min-width: 280px !important;
}

@media screen and (min-width: 1199px) {
  .themeTable__title-reportMembers {
    width: 416px !important;
  }
}

.themeTable__title-reportInvoices {
  min-width: 180px !important;
}

@media screen and (min-width: 1199px) {
  .themeTable__title-reportInvoices {
    width: 240px !important;
  }
}

.themeTable__title-paidInvoices {
  min-width: 180px !important;
}

@media screen and (min-width: 1199px) {
  .themeTable__title-paidInvoices {
    width: 240px !important;
  }
}

.themeTable__title-outstandingInvoices {
  min-width: 180px !important;
}

@media screen and (min-width: 1199px) {
  .themeTable__title-outstandingInvoices {
    width: 240px !important;
  }
}

.themeTable__title-overdueInvoices {
  min-width: 180px !important;
}

@media screen and (min-width: 1199px) {
  .themeTable__title-overdueInvoices {
    width: 240px !important;
  }
}

.themeTable__title-voidInvoices {
  min-width: 180px !important;
}

@media screen and (min-width: 1199px) {
  .themeTable__title-voidInvoices {
    width: 254px !important;
  }
}

.themeTable .title-invoice--all {
  width: 178px !important;
}

.themeTable .title-invoice--outstanding {
  width: 176px !important;
}

.themeTable .title-invoice--paid {
  width: 178px !important;
}

.themeTable .title-invoice--overdue {
  width: 208px !important;
}

.themeTable .data-invoice a {
  color: #2e3b44;
}

.themeTable .data-invoice a:hover {
  color: #3eb53e;
}
/* .themeTable .data-invoice a:hover .clipboard {
  display: block;
} */

.themeTable .nk-tb-actions {
  justify-content: start;
}

.themeTable .overdue .data-dueDate .badge-sm {
  padding: 5px;
}

.themeTable .overdue .tag__icon {
  display: inline-block;
}

.themeTable .gx-10 {
  margin-left: -0.3571rem;
  margin-right: -0.3571rem;
  display: flex;
}

.themeTable .gx-10 > li {
  padding: 0 0.3571rem;
}

.themeTable .tool__tip:hover .themeTable .tool__tip__content {
  top: 107%;
}

.themeTable .tool__tip__content {
  top: -187% !important;
  left: 51% !important;
  transform: translateX(-50%);
  cursor: pointer;
}

.themeTable
  .tag__wrapper
  .tool__tip:hover
  .themeTable
  .tag__wrapper
  .tool__tip__content {
  top: 107%;
}

.themeTable .tag__wrapper .tool__tip__content {
  left: 51%;
  top: -187%;
  transform: translateX(0);
}

.custom-filters {
  position: relative;
  z-index: 9;
  opacity: 0;
  width: 100%;
}

@media screen and (min-width: 768px), print {
  .custom-filters {
    position: absolute;
    right: 15px;
    left: auto;
    top: 0px;
    width: auto;
  }
}

.custom-filters .btn {
  border-radius: 8px;
}

@media screen and (min-width: 768px), print {
  .clients .custom-filters,
  .members .custom-filters {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media screen and (min-width: 992px), print {
  .clients .custom-filters,
  .members .custom-filters {
    position: absolute;
    right: 15px;
    left: auto;
    top: 9px;
  }
}

@media screen and (max-width: 480px) {
  .clients .custom-filters .nk-block-tools,
  .members .custom-filters .nk-block-tools {
    margin: 0 !important;
  }

  .clients .custom-filters .nk-block-tools li,
  .members .custom-filters .nk-block-tools li {
    padding: 0 !important;
  }
}

@media screen and (max-width: 480px) {
  .custom-filters .s-16 {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    margin: 0;
  }

  .custom-filters .s-16 li {
    margin-bottom: 10px;
    padding: 0;
  }
}

@media screen and (max-width: 480px) {
  .custom-filters ul,
  .custom-filters li,
  .custom-filters a {
    width: 100%;
  }
}




.custom-filters .dropdown-menu {
  min-width: 200px;
  padding: 15px 0;
  border-radius: 10px;
}

.custom-filters .dropdown-menu li {
  padding: 10px 15px;
}

.custom-filters .dropdown-menu li:hover {
  background: #f6fafa;
}

.DTFC_LeftBodyLiner {
  overflow-y: initial !important;
  width: auto !important;
}

.DTFC_LeftBodyLiner thead tr {
  height: 0 !important;
}

.DTFC_LeftBodyLiner thead tr th.sorting:before,
.DTFC_LeftBodyLiner thead tr th.sorting:after,
.DTFC_LeftBodyLiner thead tr th.sorting_asc:before,
.DTFC_LeftBodyLiner thead tr th.sorting_asc:after,
.DTFC_LeftBodyLiner thead tr th.sorting_desc:before,
.DTFC_LeftBodyLiner thead tr th.sorting_desc:after,
.DTFC_LeftBodyLiner thead tr th.sorting_asc_disabled:before,
.DTFC_LeftBodyLiner thead tr th.sorting_asc_disabled:after,
.DTFC_LeftBodyLiner thead tr th.sorting_desc_disabled:before,
.DTFC_LeftBodyLiner thead tr th.sorting_desc_disabled:after {
  display: none !important;
}

.DTFC_LeftBodyLiner .themeTable tbody tr td:last-child {
  border-right-width: 0;
}

.DTFC_LeftBodyLiner .themeTable tbody tr:first-child td:last-child {
  border-top-right-radius: 0;
}

.DTFC_LeftBodyLiner .themeTable tbody tr:last-child td:last-child {
  border-bottom-right-radius: 0;
}

.dataTables_scroll {
  z-index: 0;
}

.dataTables_scrollBody {
  overflow-y: hidden !important;
  overflow-x: auto !important;
}

.dataTables_scrollBody .themeTable.DTFC_Cloned .sorting:before,
.dataTables_scrollBody .themeTable.DTFC_Cloned .sorting:after,
.dataTables_scrollBody .themeTable.DTFC_Cloned .sorting_asc:before,
.dataTables_scrollBody .themeTable.DTFC_Cloned .sorting_asc:after,
.dataTables_scrollBody .themeTable.DTFC_Cloned .sorting_desc:before,
.dataTables_scrollBody .themeTable.DTFC_Cloned .sorting_desc:after,
.dataTables_scrollBody .themeTable.DTFC_Cloned .sorting_asc_disabled:before,
.dataTables_scrollBody .themeTable.DTFC_Cloned .sorting_asc_disabled:after,
.dataTables_scrollBody .themeTable.DTFC_Cloned .sorting_desc_disabled:before,
.dataTables_scrollBody .themeTable.DTFC_Cloned .sorting_desc_disabled:after {
  display: none !important;
}

.dataTables_scrollBody thead tr {
  height: 0 !important;
}

.dataTables_scrollBody table tbody tr:first-child td {
  border-top: 1px solid #dce8eb !important;
}

.dataTables_scrollHeadInner {
  width: auto !important;
}

.toolbar__wrap {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1199px) {
  .toolbar__wrap {
    justify-content: space-between;
  }
}

.toolbar__wrap .icon__search {
  position: absolute;
  left: 20px;
  top: 20px;
  opacity: 0.6;
  transform: translateY(-50%);
}

.toolbar__wrap .filter-wrapper {
  padding-left: 30px;
}

.s-16 {
  display: flex;
  align-items: center;
  margin-right: -0.5357rem;
  margin-left: -0.5357rem;
}

.s-16 li {
  padding: 0 0.5357rem;
}

@media screen and (max-width: 480px) {
  .s-16 li {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .s-16 li .btn {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  table.dataTable thead > tr > th.sorting_asc,
  table.dataTable thead > tr > th.sorting_desc,
  table.dataTable thead > tr > th.sorting,
  table.dataTable thead > tr > td.sorting_asc,
  table.dataTable thead > tr > td.sorting_desc,
  table.dataTable thead > tr > td.sorting {
    padding-right: 16px;
  }
}

@media screen and (max-width: 480px) {
  table.dataTable thead > tr > th.sorting_asc,
  table.dataTable thead > tr > th.sorting_desc,
  table.dataTable thead > tr > th.sorting,
  table.dataTable thead > tr > td.sorting_asc,
  table.dataTable thead > tr > td.sorting_desc,
  table.dataTable thead > tr > td.sorting {
    padding-right: 9px;
  }
}

.nk-sidebar {
  width: 280px !important;
  display: flex;
}

@media screen and (min-width: 1199px) {
  .nk-sidebar {
    width: auto !important;
  }
}

@media screen and (min-width: 1199px) {
  .nk-sidebar + .nk-wrap {
    padding-left: 235px;
    padding-right: 15px;
  }
}

@media screen and (min-width: 1441px) {
  .nk-sidebar + .nk-wrap {
    padding-left: 260px;
    padding-right: 30px;
  }
}

@media screen and (min-width: 1199px) {
  .nk-sidebar.has-submenu + .nk-wrap,
  .nk-sidebar.nk-sidebar-submenu + .nk-wrap {
    padding-left: 480px;
    padding-right: 15px;
  }
}

@media screen and (min-width: 1441px) {
  .nk-sidebar.has-submenu + .nk-wrap,
  .nk-sidebar.nk-sidebar-submenu + .nk-wrap {
    padding-right: 30px;
    padding-left: 510px;
  }
}

@media screen and (min-width: 1199px) {
  .nk-sidebar.has-submenu + .nk-wrap > .nk-header-fixed,
  .nk-sidebar.nk-sidebar-submenu + .nk-wrap > .nk-header-fixed {
    left: 465px !important;
    padding: 0 15px;
  }
}

@media screen and (min-width: 1441px) {
  .nk-sidebar.has-submenu + .nk-wrap > .nk-header-fixed,
  .nk-sidebar.nk-sidebar-submenu + .nk-wrap > .nk-header-fixed {
    left: 480px !important;
    padding: 0 30px;
  }
}

.nk-sidebar-brand {
  width: 176px;
}

@media screen and (min-width: 1199px) {
  .nk-sidebar-element > div:first-of-type {
    justify-content: center !important;
  }
}

.nk-sidebar.is-light {
  border: none;
}

@media screen and (min-width: 1199px) {
  .nk-sidebar.is-light {
    border-right-color: #f6fafa;
  }
}

@media screen and (max-width: 767px) {
  .nk-sidebar-menu {
    overflow: auto;
  }
}

.nk-sidebar-head {
  width: auto;
  padding-top: 27px;
  height: 70px;
}

@media screen and (max-width: 1199px) {
  .nk-sidebar-head {
    align-items: flex-start;
  }
}

@media screen and (max-width: 991px) {
  .nk-sidebar-head {
    padding-top: 22px;
  }
}

.nk-sidebar-head .nk-menu-trigger {
  position: relative;
  right: -10px;
}

.nk-sidebar-main .nk-sidebar-head {
  height: 130px;
  margin-top: -3px;
}

@media screen and (min-width: 992px), print {
  .nk-sidebar-main .nk-sidebar-head {
    height: 150px;
  }
}

.nk-sidebar-content {
  height: calc(100vh - 150px);
}

@media screen and (min-width: 1441px) {
  .nk-sidebar-content {
    height: calc(100vh - 150px);
  }
}

.nk-sidebar-main {
  width: 100%;
}

@media screen and (min-width: 1199px) {
  .nk-sidebar-main {
    width: 220px;
  }
}

@media screen and (min-width: 1441px) {
  .nk-sidebar-main {
    width: 230px;
  }
}

.nk-sidebar-sub {
  width: 100%;
  display: none;
  border-left: 1px solid #f6fafa;
}

@media screen and (min-width: 1199px) {
  .nk-sidebar-sub {
    width: 240px;
  }
}

@media screen and (min-width: 1441px) {
  .nk-sidebar-sub {
    width: 250px;
  }
}

@media screen and (min-width: 1199px) {
.nk-sidebar-sub.active {
  display: block;
}
}

.nk-sidebar-sub .nk-sidebar-head {
  padding-left: 30px;
}

@media screen and (min-width: 1199px) {
  .nk-sidebar-submenu + .nk-wrap {
    padding-left: 510px;
  }
}


@media screen and (max-width: 1199px) {
  .nk-sidebar-submenu + .nk-wrap .nk-header-fixed + .nk-content {
    margin-top: 60px;
  }
}

/* @media screen and (max-width: 767px) {
  .nk-sidebar-submenu + .nk-wrap .nk-header-fixed + .nk-content {
    margin-top: 130px;
  }
} */

@media screen and (min-width: 1199px) {
  .nk-sidebar-submenu .nk-sidebar-sub {
    display: block;
  }
}

.nk-sidebar .has-border {
  border-right: 1px solid #dce8eb;
}

.nk-sidebar-company {
  padding: 12px 0;
  position: absolute;
  left: 15px;
  top: 68px;
  z-index: 9;
}

@media screen and (min-width: 481px), print {
  .nk-sidebar-company {
    padding: 18px 0;
  }
}

@media screen and (max-width: 1199px) {
  .nk-sidebar-company {
    width: calc(100% - 30px);
  }
}

@media screen and (min-width: 1441px) {
  .nk-sidebar-company {
    left: 20px;
  }
}

@media screen and (max-width: 991px) {
  .nk-sidebar-company {
    top: 55px;
  }
}

.nk-sidebar-company .btn-company {
  height: 46px;
  font-size: 14px;
  background-color: #f6f7fa;
  width: 100%;
  border: 1px solid #dce8eb;
  border-radius: 6px;
  padding: 0 8px;
  color: #2e3b44;
}

.nk-sidebar-company .btn-company:hover, .data-members .btn-company:hover{
  border-color: #3eb53e !important;
  box-shadow: 0 0 0.1rem #32b92b  !important;
}
.nk-sidebar-company .btn-company:focus, .data-members .btn-company:focus{
  border-color: #178617 !important;
  box-shadow: 0 0 0.1rem #248d1e  !important;
}

.data-members .btn-company:hover, .data-members .btn-company:focus{
  background-color: rgb(253, 244, 244) !important;
}

.nk-sidebar-company .btn-company img {
  margin-right: 8px;
  max-width: 32px;
  height: auto;
}

@media screen and (min-width: 1199px) {
  .nk-sidebar-company .btn-company {
    width: 190px;
  }
}

.nk-sidebar-company .dropdown {
  width: 100%;
}

.nk-sidebar-company .dropdown-menu {
  padding: 10px 0 14px 0;
  border-radius: 10px;
  border: 1px solid #dce8eb;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
}

.nk-sidebar-company .dropdown-menu .dropdown-item {
  padding: 6px 14px;
}

.nk-sidebar-company .dropdown-menu .dropdown-item:hover {
  color: #2e3b44;
  background-color: transparent;
}

.nk-sidebar-company .dropdown-menu figure {
  border: 1px solid #dce8eb;
  max-width: 26px;
  height: auto;
  text-align: center;
  margin-right: 5px;
  border-radius: 6px;
}

.nk-sidebar-company .dropdown-menu .custom-radio {
  padding-left: 45px;
}

.nk-sidebar-company .dropdown-menu .custom-radio .custom-control-label:before {
  left: -60px;
  top: 3px;
  z-index: 999999;
  width: 20px;
  height: 20px;
  border: 1px solid #b3bfc5;
}

.nk-sidebar-company .dropdown-menu .custom-radio .custom-control-label:after {
  top: 8px;
  left: -55px;
  z-index: 999999;
  color: #fff;
  width: 10px;
  height: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}

.nk-sidebar-company
  .dropdown-menu
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #3eb53e;
  border: 1px solid #3eb53e;
}

.nk-sidebar-company .dropdown-menu .btn-primary {
  font-size: 14px;
  min-width: 160px;
  min-height: 36px;
  padding: 0;
  margin-top: 8px;
}

.nk-sidebar-company .dropdown-menu .btn-primary span {
  max-width: 16px;
  margin-right: 8px;
}

.nk-sidebar-footer {
  border-top: 1px solid #dce8eb;
  padding: 16px 12px 12px 16px;
}

.nk-sidebar-footer .dropdown-toggle {
  display: block;
  width: 100%;
  z-index: 9999;
  background-color: #fff;
}

.nk-sidebar-footer .dropdown-menu {
  top: -16px !important;
  left: -20px !important;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  transition: transform 0.5s;
}

.nk-sidebar-footer .dropdown-menu .link-list {
  padding: 5px 0;
}

.nk-sidebar .nk-menu > li .nk-menu-sub .nk-menu-link {
  padding: 10px 25px 10px 35px;
}

.nk-sidebar .has-sub.active > .nk-menu-toggle:after {
  color: #3eb53e;
}

.nk-menu-item {
  padding: 0 0 0 12px;
}

/* .nk-menu-item a:hover {
  color: #dae2da;
  background: #41ca58;
} */

@media screen and (min-width: 992px), print {
  .nk-menu-item {
    padding: 3px 0 3px 12px;
  }
  
}

.nk-menu-item span svg path {
  transition: all 0.3s linear;
}

.nk-menu-item.active > .nk-menu-link {
  background: none;
  border-radius: 0;
}

@media screen and (min-width: 1199px) {
  /* .nk-menu-item.active > .nk-menu-link {
    border-right: 3px solid #3eb53e;
  } */
  .nk-menu-item.active{
    background: #e6fbe6 !important;
    border-radius: 10px;
    max-width: 200px !important;
  }
}

.nk-menu-item.active > .nk-menu-link span svg path {
  stroke: #3eb53e;
}

@media screen and (min-width: 1199px) {
  .nk-menu-toggle:after {
    display: none;
  }
}

.nk-menu-link {
  padding: 12px 18px;
}

.nk-menu-item .nk-menu-link:hover{
  background: #dff8df !important;
  border-radius: 10px;
  max-width: 200px !important;
  color: #3eb53e !important;

}


.nk-menu-link{
  background: none !important;
} 

.nk-menu-link:hover span svg path {
  stroke: #3eb53e ;
  /* stroke: #3eb53e; */
}

.nk-brand-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #edf4f5;
  padding: 6px 0;
}

@media screen and (max-width: 480px) {
  .nk-brand-mobile {
    padding: 4px 0;
  }
}

.nk-header {
  padding: 0;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  border: none;
}

.bg-white .nk-header {
  background-color: #fff;
}

@media screen and (max-width: 767px) {
  .nk-header-tools {
    padding: 8px 0;
  }
}

.nk-header-tools .btn {
  border-radius: 9px;
}

@media screen and (max-width: 767px) {
  .nk-header-tools .btn {
    width: 40px;
    min-width: 40px;
    justify-content: center !important;
    padding: 0;
  }
}

@media screen and (max-width: 767px) {
  .nk-header-tools .btn span {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .nk-header-tools .btn img {
    height: 20px;
    width: 20px;
  }
}

.nk-header-tools .nt {
  height: 40px;
  width: 40px;
  border: 1px solid #dce8eb;
  border-radius: 8px;
  background-color: #fff;
}

.nk-header-tools .nt-new:before {
  width: 10px;
  height: 10px;
  background: #ff3a5e;
  border-radius: 50%;
  border: 2px solid #fff;
  bottom:4px;
  left: 15px;
}

.nk-header-fixed {
  padding: 5px 15px;
  background-color: #ffffff;
}



.nk-header-fixed.sticky-header {
  -ms-box-shadow: 0 0 24px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0 0 24px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.05);
}

@media screen and (min-width: 1199px) {
  .nk-header-fixed {
    left: 220px !important;
    /* left: 20px; */
  }
}

@media screen and (min-width: 1441px) {
  .nk-header-fixed {
    left: 230px !important;
    /* left: 20px; */
    padding: 6px 30px;
  }
}

/* .nk-header-fixed + .nk-content {
  margin-top: 80px;
} */

/* @media screen and (max-width: 1199px) {
  .nk-header-fixed + .nk-content {
    margin-top: 127px;
  }
} */

/* @media screen and (max-width: 767px) {
  .nk-header-fixed + .nk-content {
    margin-top: 115px;
  }
} */

.nk-quick-nav-icon {
  font-size: 1.7rem;
  padding-right: 0;
}

.nk-quick-nav-icon:before {
  display: none;
}

.clientTable.themeTable .themeTable__title-invoice span {
  font-size: 0.8571rem;
  color: rgba(86, 102, 109, 0.6);
}

.clientTable.themeTable tr.clients--head {
  background: #f6fafa;
}

.clientTable.themeTable tr.clients--head th {
  color: #56666d;
  padding: 7px 0;
  border-top: 1px solid #dce8eb;
}

.clientTable.themeTable tr.clients--head th:first-child {
  border-radius: 10px 0 0 0;
  border-left: 1px solid #dce8eb;
}

.clientTable.themeTable tr.clients--head th:last-child {
  border-radius: 0 10px 0 0;
  border-right: 1px solid #dce8eb;
}

.clientTable.themeTable tr.clients--head .sorting:before,
.clientTable.themeTable tr.clients--head .sorting_asc:before,
.clientTable.themeTable tr.clients--head .sorting_desc:before,
.clientTable.themeTable tr.clients--head .sorting_asc_disabled:before,
.clientTable.themeTable tr.clients--head .sorting_desc_disabled:before {
  top: 20px !important;
}

.clientTable.themeTable tr.clients--head .sorting:after,
.clientTable.themeTable tr.clients--head .sorting_asc:after,
.clientTable.themeTable tr.clients--head .sorting_desc:after,
.clientTable.themeTable tr.clients--head .sorting_asc_disabled:after,
.clientTable.themeTable tr.clients--head .sorting_desc_disabled:after {
  top: 13px !important;
}

.clientTable.themeTable tr.clients--head th:last-child {
  border-right-width: 1px;
}

.clientTable.themeTable tr.clients--head tr:first-child th {
  border-top-width: 1px;
}

.clientTable.themeTable tr.clients--head tr:last-child th {
  border-bottom-width: 1px;
}

.clientTable.themeTable tbody tr:first-child th:first-child,
.clientTable.themeTable tbody tr:first-child td:first-child {
  border-top-left-radius: 0;
}

.clientTable.themeTable tbody tr:first-child th:last-child,
.clientTable.themeTable tbody tr:first-child td:last-child {
  border-top-right-radius: 0;
}

.clientTable.themeTable .data-name {
  color: #2e3b44;
}

.clientTable.themeTable .data-name--email {
  color: #56666d;
  font-size: 0.8571rem;
  line-height: 15px;
}

.clientTable.themeTable .data-invoice .title-invoice {
  color: #56666d;
}

.clientTable.themeTable .data-invoice .btn {
  padding: 8px 15px;
}

.DTFC_LeftHeadWrapper .clientTable.themeTable tr.clients--head th:first-child {
  border-radius: 10px 0 0 0 !important;
  border-left: 1px solid #dce8eb;
}

.DTFC_LeftHeadWrapper .clientTable.themeTable tr.clients--head th:last-child {
  border-radius: 0;
  border-right: none;
}

@media screen and (max-width: 767px) {
  .clients .nk-block .dataTables_wrapper .toolbar__wrap,
  .members .nk-block .dataTables_wrapper .toolbar__wrap {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 767px) {
  .clients .nk-block .dataTables_wrapper .toolbar__wrap .icon__search,
  .members .nk-block .dataTables_wrapper .toolbar__wrap .icon__search {
    top: 22%;
  }
}

@media screen and (max-width: 767px) {
  .clients .nk-block .dataTables_wrapper .toolbar__wrap .filter-wrapper,
  .members .nk-block .dataTables_wrapper .toolbar__wrap .filter-wrapper {
    padding: 15px 0 0 0;
    width: 100%;
  }
}

.report-content {
  padding-top: 30px;
}

.report-content #invoice-cards {
  padding-bottom: 40px;
}

.report-content #invoice-cards-2 {
  padding-bottom: 21px;
}

.report-content__analytics {
  margin-bottom: 30px;
}

.report-content__analytics .card-head {
  padding: 17px 20px 0 40px;
  margin: 0;
  border-bottom: 1px solid #dce8eb;
}

.report-content__analytics .card-head > * {
  padding: 0;
}

@media screen and (max-width: 767px) {
  .report-content__analytics .card-head {
    padding: 20px 15px 0;
  }
}

@media screen and (max-width: 767px) {
  .report-content__analytics .nav {
    justify-content: center;
  }
}

.report-content__analytics .nav-link {
  padding: 18px 0 20px;
  background: none;
}

.report-content__analytics .nav-tabs {
  border-bottom: 0;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 767px) {
  .report-content__analytics .nav-tabs .nav-item:not(:last-child) {
    padding-right: 20px;
  }
}

@media screen and (max-width: 480px) {
  .report-content__analytics .nav-tabs .nav-item:not(:last-child) {
    padding-right: 18px;
  }
}

.report-content__analytics .tab-pane {
  padding: 20px 10px;
}

@media screen and (min-width: 768px), print {
  .report-content__analytics .tab-pane {
    padding: 37px 40px 18px;
  }
}

.report-content__analytics .canvas-wrapper {
  max-height: 445px;
  max-width: 1550px;
}

.report-content__analytics .canvas-wrapper:after {
  height: calc(100% - 89px);
  background: #dce8eb;
  width: 1px;
  top: 8px;
  right: 2px;
}

.report-content__analytics .form-inline {
  justify-content: flex-end;
}

@media screen and (max-width: 767px) {
  .report-content__analytics .form-inline {
    justify-content: center;
    margin-bottom: 15px;
  }
}

.report-content__analytics
  .form-inline
  .form-control-wrapper
  + .form-control-wrapper {
  margin-left: 12px;
}

.report-content__analytics .form-inline .form-control-wrapper:first-of-type {
  width: 142px;
}

.report-content__analytics .form-inline .form-control-wrapper:last-of-type {
  width: 102px;
}

.report-content__analytics
  .form-inline
  .form-control-wrapper
  .select2-selection--single {
  height: 36px !important;
}

.report-content__analytics
  .form-inline
  .form-control-wrapper
  .select2-selection__rendered {
  padding: 11px 14px !important;
}

@media screen and (max-width: 767px) {
  .report-content .dataTables_wrapper .toolbar__wrap {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 767px) {
  .report-content .dataTables_wrapper .toolbar__wrap .icon__search {
    top: 22%;
  }
}

@media screen and (max-width: 767px) {
  .report-content .dataTables_wrapper .toolbar__wrap .filter-wrapper {
    padding: 15px 0 0 0;
  }
}

@media screen and (max-width: 480px) {
  .report-content .dataTables_wrapper .toolbar__wrap .filter-wrapper {
    width: 100%;
  }
}

.report .datatable-wrap {
  overflow: auto;
  padding: 18px 0 0;
}

.report__dataWrap {
  display: flex;
  align-items: center;
}

.report-data span {
  display: block;
  color: rgba(86, 102, 109, 0.7);
}

.datatable-custom-reports {
  text-align: left;
  color: #56666d;
}

.datatable-custom-reports.themeTable tbody td {
  padding: 1.4643rem 0.8571rem 1.4643rem 0;
  vertical-align: middle;
}

.datatable-custom-reports .btn__circle {
  border-color: #dce8eb;
  color: #56666d;
  height: 24px;
  padding: 0 0.5714rem;
  line-height: 1.4286rem;
  display: inline-flex;
  /* margin: 0 0 0 0.8571rem; */
}

.datatable-custom-reports .btn__circle--all {
  background: #155bdb;
  border-color: #155bdb;
  color: #fff;
}

.datatable-custom-reports .btn__circle--paid {
  background: #3eb53e;
  border-color: #3eb53e;
  color: #fff;
}

.datatable-custom-reports .btn__circle--outstanding {
  background: #ffae00;
  border-color: #ffae00;
  color: #fff;
}

.datatable-custom-reports .btn__circle--overdue {
  background: #ff3a5e;
  border-color: #ff3a5e;
  color: #fff;
}

.datatable-custom-reports .btn__circle--void {
  background: #b3bfc5;
  border-color: #b3bfc5;
  color: #fff;
}

.datatable-custom-reports tfoot tr td {
  vertical-align: middle;
  background: #edf4f5;
  padding: 11px 12px 11px 0;
  border: 1px solid #edf4f5;
}

.datatable-custom-reports tfoot tr td:first-child {
  border-radius: 10px 0 0 10px !important;
  padding-left: 16px;
}

.datatable-custom-reports tfoot tr td:last-child {
  border-radius: 0 10px 10px 0 !important;
}

.datatable-custom-reports tfoot:before {
  content: '';
  line-height: 20px;
  content: '.';
  color: white;
  display: block;
}

.datatable-custom-reports tfoot .btn__circle {
  margin: 0 0 0 20px;
}

.no-report {
  text-align: center;
  padding-top: 97px;
}

.no-report-wrap {
  max-width: 469px;
  color: #56666d;
}

.no-report-wrap h1 {
  margin-bottom: 13px;
}

.no-report-wrap p {
  line-height: 26px;
  font-size: 1.1429rem;
}

.no-report-wrap img {
  margin-bottom: 44px;
}

.noRecord {
  text-align: center;
  /* height: calc(100vh - 250px); */
  align-items: center;
  display: flex;
  justify-content: center;
}

body.bg-white {
  background-color: #fff;
}

.link-primary {
  color: #3eb53e !important;
}

.is-light .active > .nk-menu-link {
  color: #3eb53e;
}

.is-light .nk-menu-link {
  color: #56666d;
}

.is-light .nk-menu-link:hover {
  color: #3eb53e;
}

.is-light .nk-menu-text {
  line-height: 14px !important;
  font-size: 14px;
}

.nk-content-fluid {
  padding: 1rem 0;
}

.nk-sidebar .nk-menu {
  padding: 0;
}

.nk-wrap {
  padding-left: 15px;
  padding-right: 15px;
}

.container-fluid {
  padding: 0;
}

.user-avatar,
[class^='user-avatar']:not([class*='-group']) {
  background: rgba(86, 102, 109, 0.65);
}

[class^='user-avatar']:not([class*='-group']) {
  background: rgba(86, 102, 109, 0.5);
}

.page-item .page-link {
  color: #56666d;
  border-radius: 8px;
  min-width: 34px;
  padding: 8px;
  font-size: 14px;
}

.page-item .page-link:hover {
  color: #56666d;
  background-color: #f2fded;
  border-color: #3eb53e;
}

.page-item.active .page-link {
  color: #56666d;
  background-color: #f2fded;
  border-color: #3eb53e;
}

.custom-control-input:checked ~ .custom-control-label::before,
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  background-color: #3eb53e;
  border-color: #3eb53e;
}

.custom-control-input:checked ~ .custom-control-label::before,
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #3eb53e;
}

.form-control:focus {
  border-color: #3eb53e;
}

.text-danger {
  color: #db2a5b !important;
}

.auth {
  overflow: hidden;
}

@media screen and (min-width: 768px), print {
  .auth {
    min-height: 100vh;
  }
}

.auth__left,
.auth__right {
  height: 100vh;
}

.auth__description {
  margin: 18px 0;
}

@media screen and (min-width: 481px), print {
  .auth__description {
    margin: 30px 0;
  }
}

@media screen and (min-width: 1600px) {
  .auth__description {
    margin: 40px 0;
  }
}

.auth__description--recoveryPassword {
  margin-top: 30px;
}

.auth__description p {
  margin-bottom: 0;
}

.auth__description p:first-of-type {
  margin-bottom: 8px;
}

.auth__description p:last-of-type {
  color: #56666d;
}

.auth__form .d-flex {
  justify-content: space-between;
  margin: 30px 0 20px 0;
}

.auth__form label {
  color: #445668;
}

.auth__form .terms-conditions {
  color: #6d7e86;
}

.auth__banner {
  width: 100%;
  height: 100vh;
}

.auth-login .auth__left {
  background-color: #ffff;
  z-index: 99;
  min-height: 100vh;
  height: auto;
}

@media screen and (min-width: 768px), print {
  .auth-login .auth__left {
    max-width: 570px;
    height: 100vh;
    overflow-y: auto;
  }
}

@media screen and (min-width: 1441px) {
  .auth-login .auth__left {
    max-width: 650px;
  }
}

@media screen and (min-width: 1600px) {
  .auth-login .auth__left {
    max-width: 700px;
  }
}

@media screen and (min-width: 1800px) {
  .auth-login .auth__left {
    max-width: 741px;
  }
}

.auth-login .auth__logo {
  margin-bottom: 28px;
  max-width: 176px;
}

.auth-login .auth__logo img {
  width: 100%;
}

@media screen and (min-width: 768px), print {
  .auth-login .auth__logo {
    max-width: 216px;
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1600px) {
  .auth-login .auth__logo {
    margin-bottom: 80px;
  }
}

@media screen and (min-width: 1441px) {
  .auth-login .auth__logo--forgotPassword {
    margin-bottom: 147px;
  }
}

@media screen and (min-width: 1441px) {
  .auth-login .auth__logo--recoveryPassword {
    margin-bottom: 206px;
  }
}

@media screen and (min-width: 1441px) {
  .auth-login .auth__logo--newPassword {
    margin-bottom: 133px;
  }
}

.auth-login .auth__more {
  margin-top: 40px;
}

@media screen and (min-width: 768px), print {
  .auth-login .auth__more {
    margin-top: 50px;
  }
}

@media screen and (min-width: 1600px) {
  .auth-login .auth__more {
    margin-top: 50px;
  }
}

/* .auth-login .auth__banner {
    background-color: #2E3B44
} */

.auth-login .auth__banner-content {
  position: relative;
  z-index: 99;
  max-width: 837px;
  margin: 0 auto;
  padding: 0 25px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.auth-login .login p {
  padding: 2% 15%;
}
/* 
@media screen and (min-width: 1441px) {
    .auth-login .auth__banner-content {
        padding-top: 130px;
        justify-content: flex-start
    }
} */

.auth-login .auth__banner-content .text-2 {
  margin-bottom: 40px;
  max-width: 380px;
  color: #fff;
}

.auth-login .auth__banner-content .text-2 span {
  color: #ffae00;
}

@media screen and (min-width: 1441px) {
  .auth-login .auth__banner-content .text-2 {
    margin-bottom: 69px;
  }
}

.auth-login .auth__banner-content .img-wrapper {
  border-radius: 10px;
  overflow: hidden;
  /* margin-bottom: 120px */
}

@media screen and (min-width: 1441px) {
  .auth-login .auth__banner-content .img-wrapper {
    margin-bottom: 0;
  }
}

.auth-login .auth__banner-background {
  position: absolute;
  top: 0;
  right: 0;
}

.auth-login .auth__banner-background svg {
  height: 100vh;
  width: auto;
}

.auth-login .auth__banner .parallelogram-primary {
  bottom: -54px;
  left: -8%;
}

.auth-login .auth__banner .parallelogram-warning {
  top: -54px;
  right: 14%;
}

.auth-login .auth__content {
  width: 100%;
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (min-width: 481px), print {
  .auth-login .auth__content {
    max-width: 478px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 768px), print {
  .auth-login .auth__content {
    max-width: 356px;
    padding-top: 100px;
  }
}

@media screen and (min-width: 1441px) {
  .auth-login .auth__content {
    margin-right: 120px;
    padding-top: 135px;
    justify-content: flex-start;
  }
}

@media screen and (min-width: 1600px) {
  .auth-login .auth__content {
    margin-right: 135px;
  }
}

@media screen and (min-width: 1800px) {
  .auth-login .auth__content {
    margin-right: 145px;
    padding-top: 156px;
  }
}

@media screen and (max-width: 767px) {
  .auth-register .auth__left {
    order: 1;
  }
}

@media screen and (min-width: 768px), print {
  .auth-register .auth__left {
    max-width: 900px;
  }
}

.auth-register .auth__right {
  min-height: 100vh;
  height: auto;
}

@media screen and (min-width: 768px), print {
  .auth-register .auth__right {
    overflow-y: auto;
  }
}

.auth-register .auth__logo {
  position: absolute;
  top: 48px;
  left: 15%;
  z-index: 9;
}

@media screen and (min-width: 1199px) {
  .auth-register .auth__logo {
    left: 40%;
    width: 216px;
    /* top: 11%; */
    left: 240px;
    width: 216px;
    left: 240px;
    width: 216px;
    left: 40%;
    width: 216px;
    /* top: 11%; */
  }

  .auth-register .auth__logo img {
    width: 100%;
  }
}

/* .auth-register .auth__banner {
  background: url('../images/auth/signup-bg.png') no-repeat center;
  background-size: cover;
} */
.auth-register .auth__banner {
  background: url('../images/auth/signup-bg.png') no-repeat center;
  background-size: cover;
}

.auth-register .auth__banner:before {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  background: rgba(46, 59, 68, 0.55);
  mix-blend-mode: multiply;
}

.auth-register .auth__banner .parallelogram-primary {
  left: -8%;
  bottom: 31px;
}

.auth-register .auth__banner .parallelogram-warning {
  bottom: -54px;
  left: 9%;
}

.auth-register .auth__banner .icon-banner-dots {
  right: 88px;
}

.auth-register .auth__content {
  width: 100%;
  padding: 50px 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

@media screen and (min-width: 481px), print {
  .auth-register .auth__content {
    max-width: 420px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 768px), print {
  .auth-register .auth__content {
    max-width: 356px;
    justify-content: center;
  }
}

@media screen and (min-width: 992px), print {
  .auth-register .auth__content {
    /* padding: 40px 0; */
    padding: 0;
  }
}

.auth-register .auth__content .auth__more {
  position: static;
  top: 57px;
  right: 240px;
  margin-bottom: 25px;
}

@media screen and (min-width: 1600px) {
  .auth-register .auth__content .auth__more {
    position: absolute;
    margin-bottom: 0;
  }

  @media (min-width: 1400px) {
    .signup-img {
      margin: 50% 0% 0% 0% !important;
      position: static !important;
      top: 11%;
      left: 20%;
      z-index: 9;
    }
  }
  @media (max-width: 1300px) {
    .signup-img {
      margin: 70% 0% 0% 5% !important;
      position: static !important;
      top: 11%;
      left: 20%;
      z-index: 9;
    }
  }

  @media screen and (max-width: 900px) {
    .signup-img {
      margin: 0% !important;
      padding: 5% !important;
      padding-top: 5% !important;
    }

    .signup-logo-div {
      position: absolute;
      z-index: 9;
      top: -70px !important;
      left: -10px !important;
      padding: 10% !important;
      padding-left: 20% !important;
    }
  }

  /* @media (max-width: 860px) {
  .signup-img {
    margin: 0% !important;
    padding: 5% !important;
    padding-top: 5% !important;
  }
  .signup-logo-div {
    position: initial !important;
    z-index: 9;
    padding: 10% !important;
    padding-left: 20% !important;
  }
} */

  @media screen and (min-width: 1600px) {
    .auth-register .auth__content {
      /* padding-top: 120px; */
      margin-left: 215px;
      /* justify-content: flex-start; */
    }
  }

  .passcode-switch.form-icon {
    height: 44px;
    top: auto;
    transform: none;
  }

  .visibility {
    background: url('../images/auth/icon-eye.svg');
    width: 14px;
    height: 14px;
  }

  .passcode-switch .is-shown .visibility {
    background: url('../images/auth/icon-eye-off.svg');
    width: 14px;
    height: 14px;
  }

  /* .password-meter {
    margin-top: 5px;
  }

  .password-meter__indicator {
    height: 3px;
    display: none;
    align-items: center;
    justify-content: space-around;
  }

  .password-meter__indicator span {
    height: 4px;
    width: 85px;
    border-radius: 2px;
    background-color: #dce8eb;
  }

  .password-meter__indicator span:not(:last-of-type) {
    margin-right: 8px;
  }

  .password-meter__indicator span.weak.active {
    background-color: #dc3545;
  }

  .password-meter__indicator span.medium.active {
    background-color: #ffae00;
  }

  .password-meter__indicator span.okay.active {
    background-color: #155bdb;
  }

  .password-meter__indicator span.strong.active {
    background-color: #4dc647;
  }

  .password-meter__text {
    color: #56666d;
    margin-top: 10px;
  } */

  /* .invoice-wrap {
    max-width: 630px;
  }

  .invoice-wrap .top-row .form-label {
    margin-bottom: 1.4286rem;
  }

  .invoice-wrap .top-row .form-group--file-upload .form-label {
    color: #2e3b44;
  }

  .invoice-wrap .top-row .form-group--file-upload .custom-file {
    width: auto;
  }

  .invoice-wrap .top-row .form-group--file-upload .details {
    margin-left: 10px;
  }

  .invoice-wrap .top-row .form-group--color .form-label {
    color: #2e3b44;
  } */

  /* .form-group--color .color-preview {
    height: 20px;
    width: 20px;
    border-radius: 6px;
    margin-right: 0.8571rem;
  }

  .invoice-wrap .top-row .form-group--color .color-preview:after {
    content: '';
    height: 100%;
    width: 42%;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
  } */

  /* .invoice-wrap .top-row .form-group--color .text-9,
  .invoice-wrap .top-row .form-group--color .btn__circle,
  .invoice-wrap .top-row .form-group--color .themeTable__title,
  .invoice-wrap
    .top-row
    .form-group--color
    .clientTable.themeTable
    tr.clients--head
    th,
  .clientTable.themeTable
    tr.clients--head
    .invoice-wrap
    .top-row
    .form-group--color
    th,
  .invoice-wrap
    .top-row
    .form-group--color
    #upgrade-plan
    .form-group
    .btn-radio
    label
    span,
  #upgrade-plan
    .form-group
    .btn-radio
    label
    .invoice-wrap
    .top-row
    .form-group--color
    span {
    line-height: 20px;
    cursor: pointer;
    color: #155bdb;
  }

  .invoice-wrap .form-group:not(:last-of-type) {
    margin-bottom: 32px;
  }

  @media screen and (max-width: 480px) {
    .invoice-wrap .form-group:not(:last-of-type) {
      margin-bottom: 20px;
    }
  }

  .invoice-wrap .invoice-num .form-control {
    width: 300px;
  }

  .invoice-wrap .custom-switch .custom-control-label {
    max-width: 338px;
  } */

  .uploadfile {
    text-overflow: ellipsis;
    max-width: 240px;
    overflow: hidden;
    white-space: nowrap;
  }

  /* .account-wrap {
    margin-top: 0.5714rem;
  }

  @media screen and (max-width: 767px) {
    .account-wrap {
      margin-top: 0;
    }
  }

  @media screen and (min-width: 1199px) {
    .account-wrap {
      max-width: 800px;
    }
  }

  .account-wrap .nk-block p {
    margin-bottom: 0;
  }

  .account-wrap .nk-block + .nk-block {
    padding-top: 47px;
  }

  @media screen and (max-width: 767px) {
    .account-wrap .nk-block + .nk-block {
      padding-top: 30px;
    }
  }

  .account-wrap .nk-block-head {
    padding-bottom: 1rem;
  }

  @media screen and (max-width: 767px) {
    .account-wrap .nk-block-head {
      padding-bottom: 0.5rem;
    }
  }

  @media screen and (min-width: 768px), print {
    .account-wrap .nk-block-text {
      max-width: 400px;
    }
  }

  @media screen and (min-width: 1441px) {
    .account-wrap .nk-block-text {
      max-width: 450px;
    }
  }

  .account-wrap .nk-block:first-of-type .text-5,
  .account-wrap
    .nk-block:first-of-type
    #upgrade-plan
    .form-group
    .btn-radio
    label,
  #upgrade-plan
    .form-group
    .btn-radio
    .account-wrap
    .nk-block:first-of-type
    label {
    color: #ff3a5e;
    margin-bottom: 0.7143rem;
  }

  .account-wrap .nk-block:first-of-type p {
    color: #454c50;
  }

  .account-wrap .nk-block:first-of-type .nk-block-head {
    padding-bottom: 1rem;
  }

  @media screen and (max-width: 767px) {
    .account-wrap .nk-block:first-of-type .nk-block-head {
      padding-bottom: 0.5rem;
    }
  }

  .account-wrap .nk-block:nth-of-type(2) .text-6 {
    color: #56666d;
    line-height: 1.625;
  }

  .account-wrap .nk-block:nth-of-type(3) .form-group-email {
    opacity: 0.7;
  }

  .account-wrap .nk-block-title {
    color: #2e3b44;
  }

  @media screen and (max-width: 767px) {
    .account-wrap .nk-block-between {
      flex-wrap: wrap;
      flex-direction: column;
    }
  }

  @media screen and (max-width: 480px) {
    .account-wrap .nk-block-between .form-group:not(:last-child) {
      margin-bottom: 0.9375rem;
    }
  }

  @media screen and (max-width: 767px) {
    .account-wrap .nk-block-actions {
      margin-top: 16px;
    }
  }

  @media screen and (max-width: 480px) {
    .account-wrap .nk-block-actions {
      width: 100%;
    }
  }

  @media screen and (max-width: 480px) {
    .account-wrap .nk-block-actions .btn {
      width: 100%;
    }
  }

  .account-wrap .actions {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  @media screen and (max-width: 767px) {
    .account-wrap .actions {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  } */

  /* .account-wrap .card-inner {
    padding: 2.1429rem 2.1429rem 2.1429rem 1.7143rem;
  }

  @media screen and (max-width: 480px) {
    .account-wrap .card-inner {
      padding: 25px 15px;
    }
  }

  .account-wrap .card-inner .btn {
    border: 1px solid #4b88e9;
    background-color: #e7f3fe;
    color: #4b88e9;
  }

  .account-wrap .card-inner .btn:hover {
    background: #cfe6fd;
  }

  @media screen and (max-width: 480px) {
    .account-wrap .card-inner .btn {
      width: 100%;
    }
  }

  @media screen and (max-width: 767px) {
    .account-wrap .card-inner .nk-block-between > * {
      width: 100%;
    }
  }

  .account-wrap .form-control {
    width: 300px;
  }

  @media screen and (max-width: 767px) {
    .account-wrap .form-control {
      width: 100%;
    }
  } */

  /* .btn-radio {
    border: 1px solid #cccfd8;
    border-radius: 5px;
    width: 100%;
  }

  .btn-radio.active {
    border-color: #3eb53e;
    color: #3eb53e;
  }

  #upgrade-plan {
    color: #56666d;
  }

  #upgrade-plan .modal-logo {
    margin-bottom: 30px;
  }

  #upgrade-plan h6 {
    color: #3eb53e;
    margin-bottom: 30px;
    border-bottom: 2px solid #3eb53e;
    padding-bottom: 10px;
  }

  @media screen and (max-width: 480px) {
    #upgrade-plan h6 {
      margin-bottom: 18px;
    }
  }

  #upgrade-plan .custom-radio {
    padding-left: 0;
  }

  #upgrade-plan .custom-radio .custom-control-label::before {
    left: 14px;
    top: 20px;
  }

  #upgrade-plan .custom-radio .custom-control-label::after {
    left: 14px;
    top: 20px;
  }

  #upgrade-plan .form-group .btn-radio label {
    padding: 20px 10px 20px 45px;
    color: #56666d;
    width: 100%;
    margin-bottom: 0;
  }

  @media screen and (max-width: 480px) {
    #upgrade-plan .form-group .btn-radio label {
      padding: 15px 10px 15px 40px;
    }
  }

  #upgrade-plan .form-group .btn-radio label span {
    display: block;
  }

  #upgrade-plan .form-group .btn-radio.active label {
    color: #3eb53e;
  }

  #upgrade-plan .form-group .btn-radio.active label span {
    color: #3eb53e;
  }

  #upgrade-plan .form-row:first-of-type {
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 480px) {
    #upgrade-plan .form-row:first-of-type .form-group:first-of-type {
      margin-bottom: 14px;
    }
  }

  @media screen and (max-width: 480px) {
    #upgrade-plan .form-row:first-of-type .col {
      flex-basis: auto;
    }
  } */

  .setting-email__content {
    padding: 0;
  }

  .setting-email__content__nav .nav-tabs:before {
    border-bottom: 1px solid #dce8eb !important;
  }

  @media screen and (min-width: 1441px) {
    .setting-email__content__nav .nav-tabs {
      padding-left: 2.1429rem;
    }
  }

  .setting-email__content__nav .nav-tabs .nav-item {
    padding-right: 2.1429rem;
  }

  .setting-email__content__nav .nav-tabs .nav-item .nav-link {
    font-size: 1.0714rem;
    padding-top: 0.5714rem;
    line-height: 1.0714rem;
    color: #56666d;
  }

  .setting-email__content__nav .nav-tabs .nav-item .nav-link.active {
    color: #2d9b36;
  }

  .setting-email__content__nav .nav-tabs .nav-item .nav-link::after {
    background: #3eb53e;
  }

  .setting-email__content__tabs {
    margin: 2.1429rem 0 2.1429rem 0;
  }



  /* .payment-methods__wrap {
    max-width: 50rem;
    margin-top: 0.7143rem;
  }

  .payment-methods__wrap-heading {
    margin-bottom: 0.8571rem;
    padding: 0;
    background-color:rgb(255, 12, 12);
  }

  .payment-methods__wrap-heading h4 {
    margin-bottom: 0.4286rem;
  }

  .payment-methods__wrap-heading p {
    color: #56666d;
  }

  .payment-methods__wrap-methods {
    padding-bottom: 1rem;
    border-bottom: 1px solid #dce8eb;
  }

  .payment-methods__wrap-methods__checkbox {
    border: 1px solid #f2f2f2;
    padding: 0.7143rem 1rem 0.7143rem 0.7143rem;
    border-radius: 0.7143rem;
    margin-bottom: 1rem;
  }

  .payment-methods__wrap-methods__checkbox:not(:last-child) {
    margin-right: 1.1429rem;
  }

  @media screen and (max-width: 480px) {
    .payment-methods__wrap-methods__checkbox {
      width: 100%;
      margin-right: 0 !important;
    }
  }

  .payment-methods__wrap-methods__checkbox figure {
    border: 1px solid #dce8eb;
    min-width: 3.4286rem;
    text-align: center;
    margin-right: 0.7143rem;
    border-radius: 0.3571rem;
  }

  .payment-methods__wrap-methods__checkbox .custom-checkbox {
    padding-left: 2.1429rem;
  }

  .payment-methods__wrap-methods__checkbox
    .custom-checkbox
    .custom-control-label {
    font-weight: 500;
    font-size: 1rem;
    display: flex;
    align-items: center;
    color: #56666d !important;
  }

  .payment-methods__wrap-methods__checkbox
    .custom-checkbox
    .custom-control-label:before {
    left: -6.2857rem;
    top: 0.2143rem;
    z-index: 99;
  }

  .payment-methods__wrap-methods__checkbox
    .custom-checkbox
    .custom-control-label:after {
    top: 0.2143rem;
    left: -6.3571rem;
    z-index: 99;
  }

  .payment-methods__wrap-connect p {
    color: #56666d;
    line-height: 1.5714rem;
    margin-bottom: 1.4286rem;
  }

  .payment-methods__wrap-connect figure {
    min-width: 6.5rem;
    margin-right: 3.5714rem;
  }

  @media screen and (max-width: 767px) {
    .payment-methods__wrap-connect figure {
      margin-right: 2rem;
    }
  }

  .payment-methods__wrap-connect figure img {
    width: 100%;
    height: auto;
  }

  .payment-methods__wrap-connect__item {
    padding: 2.8571rem 0 2.5714rem 0;
    border-bottom: 1px solid #dce8eb;
  }

  @media screen and (max-width: 480px) {
    .payment-methods__wrap-connect__item {
      padding: 30px 0;
    }
  }

  .payment-methods__wrap-connect__item .btn-sm {
    min-width: 12.9286rem;
  }

  @media screen and (max-width: 480px) {
    .payment-methods__wrap-connect__item .btn-sm {
      width: 100%;
    }
  }

  .payment-methods__wrap-connect__item .btn-outline-default {
    padding: 0.6429rem;
    min-width: auto;
    min-height: auto;
  }

  .payment-methods__wrap-connect__item a {
    color: #155bdb;
  }

  .payment-methods__wrap-connect__item a span {
    margin-right: 0.5714rem;
  }

  @media screen and (max-width: 480px) {
    .payment-methods__wrap-connect__item > div {
      flex-wrap: wrap;
    }
  }

  @media screen and (max-width: 767px) {
    .payment-methods__wrap-connect__item #stripe-after > div,
    .payment-methods__wrap-connect__item #paypal-after > div {
      flex-wrap: wrap;
    }
  }

  .payment-methods__wrap-connect__item #stripe-after button,
  .payment-methods__wrap-connect__item #paypal-after button {
    margin-left: 0;
    margin-bottom: 15px;
  }

  .payment-methods__wrap-connect__item #stripe-after button:not(:last-child),
  .payment-methods__wrap-connect__item #paypal-after button:not(:last-child) {
    margin-right: 16px;
  } */
/* 
  .block-danger {
    margin: 2.7143rem 0 2.8571rem;
  }

  @media screen and (max-width: 480px) {
    .block-danger {
      margin: 30px 0;
    }
  } */

  .block-danger__title {
    font-size: 1rem;
    line-height: 1;
    margin-bottom: 10px;
    color: #2e3b44;
  }

  .block-danger__card {
    padding: 1.5rem 1.5rem 1.6429rem;
    border-radius: 6px;
    background-color: #ffefeb;
    border: 1px solid #ff3a5e;
    max-width: 700px;
  }

  .block-danger__card-inner {
    display: flex;
  }

  .block-danger__card__image {
    margin-right: 0.5714rem;
    flex-shrink: 0;
  }

  .block-danger__card__content h5,
  .block-danger__card__content p {
    line-height: 1;
  }

  .block-danger__card__content p {
    margin: 0.5rem 0 1.1429rem;
    color: #2e3b44;
  }

  @media screen and (max-width: 767px) {
    .block-danger__card__content p {
      line-height: 1.25;
    }
  }

  .block-danger__card__content .btn-danger {
    min-width: 199px;
  }

  @media screen and (max-width: 480px) {
    .block-danger__card__content .btn-danger {
      width: 100%;
    }
  }

  .company-settings-wrap .form-group .form-control {
    max-width: 300px;
  }

  @media screen and (max-width: 480px) {
    .company-settings-wrap .form-group .form-control {
      max-width: 100%;
    }
  }

  .error {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 15px;
    height: 440px;
  }

  @media screen and (max-width: 767px) {
    .error {
      justify-content: center;
    }
  }

  @media screen and (min-width: 481px), print {
    .error {
      height: 100%;
    }
  }

  .error__image {
    margin-top: auto;
  }

  @media screen and (min-width: 768px), print {
    .error__image {
      margin: 265px 0 84px;
    }
  }

  .error__brand .logo-img {
    max-width: 290px;
  }

  @media screen and (min-width: 768px), print {
    .error__brand .logo-img {
      width: 312px;
      height: 36px;
    }
  }

  .error__content {
    margin-top: auto;
  }

  .error__content p {
    margin: 12px 0 30px;
    color: #56666d;
  }

  .body-full-page {
    height: 100vh;
  }

  @media screen and (max-width: 767px) {
    .body-full-page {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  body,
  html {
    color: #56666d;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.5;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    background-color: #fff;
    font-family: 'Inter', sans-serif;
  }

  @media screen and (min-width: 768px), print {
    body,
    html {
      font-size: 14px;
    }
  }

  /* ::-webkit-scrollbar {
    width: 5px;
  } */

  /* @media screen and (min-width: 1023px) {
    ::-webkit-scrollbar {
      width: 12px;
    }
  } */

  ::-webkit-scrollbar-thumb {
    background: #c7cad1;
  }

  .errorlist {
    color: #c3463c;
  }

  @media (min-width: 992px) {
    .add-company-modal .modal-lg,
    .add-company-modal .modal-xl {
      max-width: 500px;
    }
  }
  .password-checker p {
    font-size: 13px !important;
  }
}

.company-name-img-alt {
  background-color: rgb(46, 157, 55) !important;
  width: 24px;
  height: 24px;
  border-radius: 5px;
  color: white;
  padding: 6px 9px;
}
.company-list .my-radio {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  background-color: #3eb53e;
  border: 1px solid #3eb53e;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: 0;
  cursor: pointer;
}

.company-list .form-check-input {
  position: absolute;
  margin-top: 0.85rem;
  margin-left: -1.7rem;
  border: solid 5px red !important;
}

.company-list input[type='radio']:before {
  content: '';
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
  background-color: white;
}

.company-list input[type='radio']:checked:before {
  background: #4dc647;
}

.color-hex {
  left: 22.5rem;
  position: absolute;
  z-index: 1;
}

/* .ReactTags__tags,
.ReactTags__selected,
.ReactTags__tagInput,
.ReactTags__tagInputField {
  width: 100%;
} */
.ReactTags__tagInputField {
  height: 100%;
}

.ReactTags__tagInputField {
  color: #56666d;
  min-height: 55px;
  font-size: 1rem;
  line-height: 1;
  background-color: #edf4f5;
  border: none;
  outline: 0;
  border-radius: 5px;
  padding: 14.9996px 10.0002px 1.0714rem 1.1429rem;
  box-shadow: none;
}

.ReactTags__tagInputField:focus {
  background-color: transparent !important;
  box-shadow: none;
  border: 1px solid #dce8eb !important;
}

.ReactTags__remove {
  border: 0;
  /* background-color: white; */
  padding: 10px;
}

.tag-wrapper::before {
  background-color: tomato;
}

.side-nav-list li a.active,
#side-nav-list li a.active {
  border-right: none;
  color: #3EB53E !important;
}


.nk-menu-item a.active {
  /* border-right: 3px solid #4dc647;
  color: #3eb53e !important; */
  background: #e6fbe6 !important;
  border-radius: 10px;
  max-width: 200px !important;
  color: #3EB53E  !important;
}
.nk-menu-item a.active span svg path{
  stroke: #3EB53E;
}
.nk-menu-item:hover a.active, .nk-menu-item:hover a.active span svg path {
  border-right: none;
  color: #3eb53e  !important;
  stroke: #3eb53e  !important;
}

.side-nav-list li a:hover,
#side-nav-list li a:hover {
  color: #3eb53e !important;
}

.dropdown-hover-menu .nk-menu-item a.active, .setting-item a.active{
  border-right: 3px solid #4dc647;
  color: #3eb53e !important;
} 

.side-nav-list li a,
#side-nav-list li a {
  color: #767e76 !important;
}

/* li a.active-child {
  border-right: 3px solid #4dc647;
  color: #3eb53e !important;
} */

.warning-click-here a:hover {
  color: #3eb53e !important;
}

.side-logout {
  position: absolute;
  inset: auto auto 0px 0px;
  margin: 0px;
  transform: translate(0px, -60px) !important;
  box-shadow: none;
}

.search-field {
  color: #56666d;
  min-height: 55px;
  /* font-size: 1rem; */
  line-height: 1;
  background-color: #edf4f5;
  border: none;
  outline: 0;
  border-radius: 5px;
  padding: 14.9996px 10.0002px 1.0714rem 1.1429rem;
  box-shadow: none;
}
.tax-del:hover {
  color: rgb(255, 12, 12) !important;
  transition: 0.8s;
  opacity: 0.8;
}

.tax-table{
  overflow-x: scroll;
}
.tax-table td {
  white-space: nowrap;
  width: 1%;
}

.tax-table td:last-child,
th:last-child {
  padding-right: 1rem !important;
}

.dashboard-table {
  /* overflow-x: auto !important; */
  width: 50vw;
}

/* .dashboard-table .themeTable tbody tr:nth-child(odd) td:first-child {
    background-color: white;
    overflow: hidden;
    z-index: 999 !important;
} */
/*  Styles for React Tags*/
#app {
  padding: 40px;
}

div.ReactTags__tags {
  position: relative;
}

/* Styles for the input */
div.ReactTags__tagInput {
  width: 100% !important;
  border-radius: 2px;
  display: inline-block;
}
div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  margin: 0;
  /* font-size: 12px; */
  width: 100%;
  border: 1px solid #eee;
  padding: 0 4px;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #eee;
  /* font-size: 12px; */
  display: inline-block;
  padding: 5px;
  /* margin: 0 5px; */
  cursor: move;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 2px;
}
div.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
  position: absolute;
}
div.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}
div.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}
div.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.block-danger__card {
  padding: .5rem;
  border-radius: 6px;
  background-color: #ffefeb;
  border: 1px solid #ff3a5e;
  max-width: 700px;
}
.toggle {
  transform: translateX(0%);}


  .form-element-inline{
      justify-content: space-between;
  }