.member-goal{
    color: #6c7776;
    font-weight: 500;
    border: red !important;
    display: block;

}
.member-goal table {
    width: 70rem !important;
}
.member-goal tr {
    transition: all 0.5s ease !important;
    border: 1px solid #dce8eb !important;
    border-radius: 5px;
    background: #fff !important;
}

.member-goal::before{
    line-height: 20px;
    content: '.';
    color: white;
    display: block;
}

.member-goal .progress_bar {
    display: flex;
    height: 1rem;
    overflow: hidden;
    line-height: 0px;
    font-size: 0.75rem;
    background-color: #f5f6fa;
    border-radius: 20px;

}

 .progress_bar .progress_bar-bar{
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #854fff;
    transition: width 0.6s ease;
}
.goal-icon{
width: 18px !important;
height: 18px !important;
}

